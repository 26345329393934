import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/confirm";
import {BallTriangle} from "react-loader-spinner";
import '../../styles/All.css'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from 'moment';
import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Typography from "@material-ui/core/Typography";

const FreezingList = () => {

    const getU = useGet();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const [data, setData] = useState([]);
    const [sum, setSum] = useState();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const [queryParams, setQueryParams] = useState({
        fromDate: searchParams.get("fromDate") || '1',
        toDate: searchParams.get("toDate") || '100000000000000',
    });

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };

    const handleFilterQueryParams = () => {
        const params = {}
        if (queryParams.fromDate !== '') {
            params.fromDate = queryParams.fromDate
        }
        if (queryParams.toDate !== '') {
            params.toDate = queryParams.toDate
        }

        if (Object.keys(params).length !== 0) {
            setSearchParams(params)
        }
    }

    const loadPayments = async () => {
        setIsDataLoading(true);
        setIsLoaded(true)

        // let endpoint = `admin/finance/freeze?page=${page + 1}&limit=${limit}`;
        let endpoint = `admin/finance/freeze`;

        if (queryParams.fromDate !== '') {
            endpoint += `?unixTimeStart=${searchParams.get("fromDate")}`;
        }
        if (queryParams.toDate !== '') {
            endpoint += `&unixTimeEnd=${searchParams.get("toDate")}`;
        }

        await getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setData(resp.data.freezingFinance);
                    setCount(resp.data.allCount || 0);
                }
                setIsDataLoading(false);
            })
            .catch((err) => {
                console.log(err.response)
                setData([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() => {

            });

        await getU(`admin/finance/freeze/sum`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setSum(resp?.data?.sum);
                }
            })
            .catch((err) => {
                console.log(err.response)
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (queryParams.decimal === '') {
            searchParams.delete("decimal")
            setSearchParams(searchParams);
        }
    }, [queryParams])

    useEffect(() => {
        const params = {}

        if (queryParams.fromDate !== '') {
            params.fromDate = 1
        }
        if (queryParams.toDate !== '') {
            params.toDate = 100000000000000
        }

        if (Object.keys(params).length !== 0) {
            setSearchParams(params)
        }
    }, [queryParams])

    useEffect(() => {
        loadPayments();
    }, [page, limit, searchParams]);



    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Заморозка</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Заморозка
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>

                    <>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <Box style={{padding: 15}}>
                                    <Typography>
                                        Общая суммы замороженных средств: {sum ? sum : '---'}
                                    </Typography>
                                </Box>
                                <PerfectScrollbar>
                                    <Box sx={{pt: 1, px: 2}} fullWidth>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}>

                                            <TextField
                                                fullWidth
                                                label="Начало интервала в unix"
                                                margin="normal"
                                                name="fromDate"
                                                onChange={handleChangeQueryParams}
                                                type="text"
                                                value={queryParams.fromDate}
                                                variant="outlined"
                                                style={{
                                                    marginRight: '10px'
                                                }}
                                            />

                                            <TextField
                                                fullWidth
                                                label="Конец интервала в unix"
                                                margin="normal"
                                                name="toDate"
                                                onChange={handleChangeQueryParams}
                                                type="text"
                                                value={queryParams.toDate}
                                                variant="outlined"
                                                style={{
                                                    marginRight: '10px'
                                                }}
                                            />


                                            <Button
                                                color="warning"
                                                variant="contained"
                                                onClick={handleFilterQueryParams}
                                                sx={{mt: 1, mb: 0}}
                                                style={{padding: '15px 30px', minWidth: '100px',}}
                                            >
                                                Поиск
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box sx={{minWidth: 1400}}>
                                        <Divider/>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{width: 80}}>
                                                        id
                                                    </TableCell>
                                                    <TableCell>
                                                        Аватар пользователя
                                                    </TableCell>
                                                    <TableCell>
                                                        Id пользователя
                                                    </TableCell>
                                                    <TableCell>
                                                        Почта
                                                    </TableCell>
                                                    <TableCell>
                                                        Логин
                                                    </TableCell>
                                                    <TableCell>
                                                        Время было получено
                                                    </TableCell>
                                                    <TableCell>
                                                        Сумма
                                                    </TableCell>
                                                    <TableCell>
                                                        Дата получения
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data?.map((item) => (
                                                    <TableRow hover key={item.id}>
                                                        <TableCell sx={{width: 80}}>
                                                            {item?.id || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box sx={{alignItems: 'center', display: 'flex'}}>
                                                                <Avatar
                                                                    src={
                                                                        item?.user_avatar ?
                                                                            `${item?.user_avatar}`
                                                                            :
                                                                            ''
                                                                    }
                                                                />
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.user_id || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.user_email || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.user_login || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.timeToRereceived || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.amount || '---'}
                                                        </TableCell>

                                                        <TableCell>
                                                            {item?.date_received ? moment(item?.date_received).format('mm.hh DD.MM.YYY')  : '---'}
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        labelRowsPerPage={
                                                            <span>Кол-во строк на странице:</span>}
                                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                        colSpan={7}
                                                        count={count}
                                                        rowsPerPage={limit}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeLimit}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>
                </Container>
            </Box>
        </>
    );
};

export default FreezingList;
