import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Divider,
    TextField
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {useGet, usePatch, usePost, usePut} from "../../API/request";
import {BallTriangle} from "react-loader-spinner";

const BannersEdit = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const postU = usePost();
    const patch = usePatch();
    const getU = useGet();
    const putU = usePut();

    const [values, setValues] = useState({
        title: '',
        description: '',
        image: '',
        link: '',
    });
    const [errors, setErrors] = useState({
        title: false,
        description: false,
        image: false,
        link: false,
    });

    const [isLoaded, setIsLoaded] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);
    const [dataBeforeChange, setDataBeforeChange] = useState();

    const [uploadedImg, setUploadedImg] = useState('/static/images/defaultImage.jpg');
    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setNothingChanged(false);
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };


    const imgUploaded = (event) => {
        setNothingChanged(false);
        setUploadedImg(URL.createObjectURL(event.target.files[0]));
        setValues({
            ...values,
            image: event.target.files[0]
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 3000);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.title.trim() === '') {
            validComplete = false;
            formErrors.firstName = true;
        }

        if (values.link.trim() === '') {
            validComplete = false;
            formErrors.link = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (nothingChanged) {
            showAlert('error', 'Ничего не изменилось');
            return;
        }
        if (validate()) {
            setSubmitDisabled(true);

            let data = new FormData();

            if (dataBeforeChange.title !== values.title){
                data.append("title", values.title);
            }
            if (dataBeforeChange.link !== values.link){
                data.append("link", values.link);
            }
            if (values.image !== ''){
                data.append("file", values.image);
            }

            putU(`banners/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно обновили баннер');
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', err?.response?.data?.message);
                })
                .finally(() => {
                    setSubmitDisabled(false);
                });
        }
    };


    useEffect(() => {
        setIsLoaded(true)
        getU(`banners/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    let gameData = resp.data.banner;

                    setDataBeforeChange({
                        title: gameData.title,
                        description: gameData.description,
                        link: gameData.link
                    })

                    setUploadedImg(gameData.image);
                    setValues({
                        ...values,
                        title: gameData.title || '',
                        description: gameData.description || '',
                        link: gameData.link || '',
                    })
                }
            })
            .catch((err) =>{
                showAlert('error', err?.response?.data?.message);
            })
            .finally(() =>{
                setIsLoaded(false)
            });

    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Edit banner</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Баннер
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Баннер</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>
            </Box>

            <Box sx={{ backgroundColor: 'background.default', minHeight: '85%', py: 3}}>
                <Container maxWidth={false} >
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Редактирование баннера"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <div className="itemWrapper">
                                        <div className="container">
                                            <input accept="xlsx/*" type="file" style={{display: 'none'}}
                                                   id={1}
                                                   onChange={(event) => imgUploaded(event, 1)}/>
                                            <label htmlFor={1}>
                                                <img src={uploadedImg} className="itemImg"/>
                                                <div className="middle"/>
                                            </label>
                                        </div>
                                        <div className="help-text">
                                            Доступны следующие расширения: .png .jpg .svg .bmp .tga .webp
                                        </div>
                                    </div>

                                    <TextField
                                        fullWidth
                                        label="Название"
                                        margin="normal"
                                        name="title"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.title}
                                        variant="outlined"
                                        error={errors.title}
                                    />

                                    <TextField
                                        fullWidth
                                        label="Ссылка"
                                        margin="normal"
                                        name="link"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.link}
                                        variant="outlined"
                                        error={errors.link}
                                    />

                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default BannersEdit;
