import {useEffect, useContext, useState} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    List,
    Typography
} from '@material-ui/core';
import {
    BarChart as BarChartIcon,
    Users as UsersIcon,
    Mail as MailIcon,
    DollarSign as Payment,
    Settings,
    FileText,
    File,
    DollarSign,
    Codepen,
    HardDrive,
    Slack,
    Clipboard,
    List as ListIcon, PhoneForwarded
} from 'react-feather';
import NavItem from './NavItem';
import {useGet} from '../API/request';
import {useSelector} from 'react-redux';

const itemsDesign = [
    {
        icon: BarChartIcon,
        title: 'Статистика',
        list: [
            {
                href: '/app/dashboard-common',
                icon: BarChartIcon,
                title: 'Общая'
            },
            {
                href: '/app/dashboard-boosters',
                icon: BarChartIcon,
                title: 'Бустеры'
            },
            {
                href: '/app/dashboard-booster-packs',
                icon: BarChartIcon,
                title: 'Бустер паки'
            },
            {
                href: '/app/dashboard-tokens',
                icon: BarChartIcon,
                title: 'Токены'
            }
        ]
    },
    {
        href: '/app/users',
        icon: UsersIcon,
        title: 'Пользователи'
    },
    {
        href: '/app/boosters',
        icon: FileText,
        title: 'Бустеры'
    },
    {
        href: '/app/booster-packs',
        icon: File,
        title: 'Бустер паки'
    },
    {
        href: '/app/payment',
        icon: DollarSign,
        title: 'Платежи'
    },
    {
        href: '/app/garant-bonus',
        icon: Codepen,
        title: 'Гарант бонусы'
    },
    {
        href: '/app/games',
        icon: HardDrive,
        title: 'Игры'
    },
    {
        href: '/app/freezing',
        icon: Slack,
        title: 'Заморозка'
    },
    {
        href: '/app/banners',
        icon: Clipboard,
        title: 'Баннеры'
    },
];

const itemsSystem = [
    {
        icon: Settings,
        title: 'Настройки',
        list: [
            {
                href: '/app/settings/email',
                icon: Settings,
                title: 'Почта'
            },
            {
                href: '/app/logs',
                icon: Settings,
                title: 'Логи'
            },
            {
                href: '/app/configs',
                icon: Settings,
                title: 'Конфиги'
            },
            {
                href: '/app/partnerAPI',
                icon: Settings,
                title: 'Партнерское API'
            }
        ]
    },

];

const DashboardSidebar = ({onMobileClose, openMobile}) => {

    const location = useLocation();
    const [userAva, setUserAva] = useState('');
    const [userName, setUserName] = useState('Admin');
    const ava = useSelector(state => state?.dashboard?.ava)
    const name = useSelector(state => state?.auth.user?.name)
    const getU = useGet();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    useEffect(() => {
        getU(`users`)
            .then((resp) => {
                if (resp.status === 'success') {
                    let user = resp.data.user;
                    setUserAva(user?.avatar);
                    setUserName(user?.name);

                }
            })
            .catch((err) => {

            });

    }, [ava,name]);



    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2
                }}
            >
                <Avatar
                    component={RouterLink}
                    src={userAva}
                    sx={{
                        cursor: 'pointer',
                        width: 64,
                        height: 64,
                        marginBottom: 1
                    }}
                    to="/app/account"
                />
                <Typography
                    color="textPrimary"
                    variant="h5"
                >
                    {userName || 'Admin'}
                </Typography>
            </Box>
            <Divider/>
            <Box sx={{px: 2}}>
                <List>
                    {itemsDesign.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                            list={item?.list}
                        />
                    ))}
                </List>
            </Box>
            <Divider/>
            <Box sx={{px: 2}}>
                <List>
                    {itemsSystem.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                            list={item?.list}
                        />
                    ))}
                </List>
            </Box>
            <Box sx={{flexGrow: 1}}/>
        </Box>
    );

    return (
        <>
            <Box sx={{display: {xs: 'block', lg: 'none'}}}>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                    PaperProps={{
                        sx: {
                            width: 256
                        }
                    }}
                >
                    {content}
                </Drawer>
            </Box>
            <Box sx={{display: {xs: 'none', lg: 'block'}}}>
                <Drawer
                    anchor="left"
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: 256,
                            top: 64,
                            height: 'calc(100% - 64px)'
                        }
                    }}
                >
                    {content}
                </Drawer>
            </Box>
        </>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
    onMobileClose: () => {
    },
    openMobile: false
};

export default DashboardSidebar;
