import {useDispatch, useSelector} from 'react-redux';
import {getTransport, configureTransport} from './transport';
import {logout, setTokens} from "../redux/slices/auth";
import TokenStorage from "./TokenStorage";
import {useState} from 'react';
import {useNavigate} from "react-router-dom";

const generateHook = (callback) => () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return callback(async (e) => {
        if (e?.response?.status !== 403) throw e;
        try {
            const token = await TokenStorage.getRefreshToken()

            const res = await get('auth/refresh', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            await configureTransport(res.data.token.access_token)
            dispatch(setTokens({
                accessToken: res.data.token.access_token,
                refreshToken: res.data.token.refresh_token,
            }));

            await TokenStorage.setAccessToken(res.data.token.access_token);
            await TokenStorage.setRefreshToken(res.data.token.refresh_token);
            await TokenStorage.setTokenReceived(Date.now() / 1000);

            delete e.response.config?.headers?.Authorization;
            const buf = (await getTransport()(e.response.config))?.data;
            return buf
        } catch (e) {
            TokenStorage.logOut();
            logout(dispatch)
            navigate('/login')
            throw e;
        }
    });
};

export const usePost = generateHook((middleware) => (
    (path, payload, config) => getTransport()
        .post(`/api/${path}`, payload, config)
        .then((response) => response.data)
        .catch(middleware)
));

export const useGet = generateHook((middleware) => (
    (path, token, config) => getTransport(token)
        .get(`/api/${path}`, config)
        .then((response) => response.data)
        .catch(middleware)
));

export const usePut = generateHook((middleware) => (
    (path, payload, config) => getTransport()
        .put(`/api/${path}`, payload, config)
        .then((response) => response.data)
        .catch(middleware)
));

export const useDelete = generateHook((middleware) => (
    (path, payload, config) => getTransport()
        .delete(`/api/${path}`, payload, config)
        .then((response) => response.data)
        .catch(middleware)
));

export const usePatch = generateHook((middleware) => (
    (path, payload, config) => getTransport()
        .patch(`/api/${path}`, payload, config)
        .then((response) => response.data)
        .catch(middleware)
));

export const get = (path, config) => getTransport()
    .get(`/api/${path}`, config).then((response) => response.data);

export const post = async (path, payload, config) => getTransport()
    .post(`/api/${path}`, payload, config)
    .then((response) => response.data);

export const put = (path, payload = {}) => getTransport()
    .put(`/api/${path}`, payload)
    .then((response) => response.data);

export const deleteRequest = (path, payload = {}) => getTransport()
    .delete(`/api/${path}`, payload)
    .then((response) => response.data);

export const patch = (path, payload = {}) => getTransport()
    .patch(`/api/${path}`, payload)
    .then((response) => response.data);

export const httpDelete = (path, config) => getTransport()
    .delete(`/api/${path}`, config)
    .then((response) => response.data);
