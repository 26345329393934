import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Typography,
    Input,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useParams, useNavigate} from 'react-router-dom';
import {useGet, usePut} from "../../API/request";
import {BallTriangle} from "react-loader-spinner";

const BoosterPackEdit = () => {

    const {id} = useParams();
    const navigate = useNavigate();

    const getU = useGet();
    const putU = usePut();

    const [values, setValues] = useState({
        pool_booster_pack: '',
        price_buy: '',
        price_open: '',
        name: '',
    });
    const [errors, setErrors] = useState({
        pool_booster_pack: false,
        price_buy: false,
        price_open: false,
    });
    const [isChanged, setIsChanged] = useState({
        pool_booster_pack: false,
        price_buy: false,
        price_open: false,
    });

    const [isLoaded, setIsLoaded] = useState(true);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
        setIsChanged({
            ...isChanged,
            [event.target.name]: true
        });
    };



    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };


    const submit = () => {
        if (nothingChanged) {
            showAlert('error', 'Нет изменений');
            return;
        }
        setSubmitDisabled(true);

        const data = {}

        if (isChanged.pool_booster_pack){
            data.pool_booster_pack = values.pool_booster_pack
        }
        if (isChanged.price_buy){
            data.price_buy = values.price_buy
        }
        if (isChanged.price_open){
            data.price_open = values.price_open
        }

        putU(`admin/boosterPack/boosterPackPool/${id}`, data)
            .then((resp) => {
                if (resp.status === 'success') {
                    showAlert('success', 'Данные успешно обновленны');
                } else {
                    showAlert('error', 'Ошибка');
                }
            })
            .catch((err) => {
                console.log(err.response)
                showAlert('error', 'Ошибка сервера');

            })
            .finally(() => {
                setSubmitDisabled(false);
            })
        ;
    };

    useEffect(() => {
        getU(`boosterPack/getOne/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setValues({
                        name: resp.data.boosterPack.name,
                        pool_booster_pack: resp.data.boosterPack.pool_booster_pack,
                        price_buy: resp.data.boosterPack.price_buy,
                        price_open: resp.data.boosterPack.price_open
                    })
                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при попытке получить бустер пак');
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Edit booster-pack</title>
            </Helmet>

            <Box className="headerWrapper" sx={{pb: 2}}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    {values.name}
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Бустер паки</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default', minHeight: '100%'}}>
                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title={`Редактирование бустер пака`}
                                />
                                <Divider/>

                                <CardContent>
                                    <TextField
                                        fullWidth
                                        label="Пулл бустер пака"
                                        margin="normal"
                                        name="pool_booster_pack"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.pool_booster_pack}
                                        variant="outlined"
                                        error={errors.pool_booster_pack}
                                        inputProps={{maxLength: 12}}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Цена покупки"
                                        margin="normal"
                                        name="price_buy"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.price_buy}
                                        variant="outlined"
                                        error={errors.price_buy}
                                        inputProps={{maxLength: 12}}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Цена открытия"
                                        margin="normal"
                                        name="price_open"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.price_open}
                                        variant="outlined"
                                        error={errors.price_open}
                                        inputProps={{maxLength: 12}}
                                    />

                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>

                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default BoosterPackEdit;
