import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Typography,
    Input,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useNavigate, useParams} from 'react-router-dom';
import {useGet, usePost} from "../../../API/request";
import React, {useEffect, useState} from "react";
import {BallTriangle} from "react-loader-spinner";

const TokensUserAdd = () => {

    const {id} = useParams();
    const navigate = useNavigate();

    const postU = usePost();
    const getU = useGet();

    const [tokens, setTokens] = useState({});

    const [values, setValues] = useState({
        token_id: '',
        amount: '',
    });
    const [errors, setErrors] = useState({
        token_id: false,
        amount: false,
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };


    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 3000);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (!values.amount) {
            validComplete = false;
            formErrors.amount = true;
        }

        if (values.token_id === '') {
            validComplete = false;
            formErrors.token_id = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const clearForm = () => {
        let vals = {...values};

        for (let key in vals) {
            vals[key] = '';
        }

        setValues(vals);
    };

console.log(values)

    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            const tokens = [
                {
                    token_id: values.token_id,
                    amount: values.amount,
                }
            ]

            postU(`tokensBooster/refillUser/${id}`, {tokens})
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно добавили токена');
                        clearForm();
                    } else {
                        showAlert('error', 'Ошибка при добавление токена');
                    }
                })
                .catch((err) => {
                    console.log(err.response)
                    showAlert('error', err?.response?.data?.message);
                });
        }
    };


    const getData = async () => {
        setIsLoaded(true)

        await getU(`tokensBooster`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setTokens(resp.data.tokens)
                }
            })
            .catch(() => {
                showAlert('error', 'Ошибка при получение токенов пользователя');
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }

    useEffect(() => {
        getData()
    }, []);


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Добавление токена</title>
            </Helmet>
            <Box className="headerWrapper" sx={{ pb: 2 }}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Добавление токена
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Пользователи</li>
                    <li>/</li>
                    <li onClick={() => navigate("/app/users")}>Информация</li>
                    <li>/</li>
                    <li>Добавление токена</li>
                </ul>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 2 }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Добавление нового токена"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="token_id">Токен за который покупается бустер</InputLabel>
                                        <Select
                                            labelId="token_id"
                                            name="token_id"
                                            value={values.token_id}
                                            error={errors.token_id}
                                            label="Токен за который покупается бустер"
                                            onChange={handleChange}
                                        >
                                            {
                                                tokens.map(item =>
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        fullWidth
                                        label="Сумма"
                                        margin="normal"
                                        name="amount"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.amount}
                                        variant="outlined"
                                        error={errors.amount}
                                    />

                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Добавить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default TokensUserAdd;
