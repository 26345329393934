import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/confirm";
import {BallTriangle} from "react-loader-spinner";
import '../../styles/All.css';
import moment from "moment";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const UserFreezing = () => {


    const {id} = useParams();
    const getU = useGet();
    const navigate = useNavigate();

    const [isLoaded, setIsLoaded] = useState(true);
    const [data, setData] = useState([]);

    const loadData = () => {
        setIsLoaded(true)

        getU(`admin/user/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setData(resp?.data?.user?.farming?.arrayFreeze)
                }
            })
            .catch((err) => {
                console.log(err.response)
                setData([]);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };



    useEffect(() => {
        loadData();
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>User freezing</title>
            </Helmet>
            <Box className="headerWrapper" sx={{pb: 2}}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Замороженные средства пользователя
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Пользователи</li>
                    <li>/</li>
                    <li>Информация</li>
                    <li>/</li>
                    <li>Замороженные средства пользователя</li>
                </ul>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>

                    <>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 1000}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{width: 80}}>
                                                        Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Cумма
                                                    </TableCell>
                                                    <TableCell>
                                                        Дата получения
                                                    </TableCell>
                                                    <TableCell>
                                                        Время до повторного получения
                                                    </TableCell>
                                                    <TableCell>
                                                        Id пользователя
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.map((item) => (
                                                    <TableRow hover key={item.id}>
                                                        <TableCell sx={{width: 80}}>
                                                            {item?.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.amount || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.date_received ? moment(item?.date_received).format('mm.hh DD.MM.YYY')  : '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.timeToRereceived || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.user_id || '---'}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>

                </Container>
            </Box>
        </>
    );
};

export default UserFreezing;
