import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Typography,
    Input,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useNavigate, useParams} from 'react-router-dom';
import {useGet, usePost} from "../../API/request";
import React, {useEffect, useState} from "react";
import {BallTriangle} from "react-loader-spinner";

const BoosterPackAddPrize = () => {

    const {boosterPackId} = useParams();

    const navigate = useNavigate();

    const postU = usePost();
    const getU = useGet();

    const [prizesTypes, setPrizesTypes] = useState([])

    const [title, setTitle] = useState('')

    const [values, setValues] = useState({
        prizesTypes: '',
        chance_drop_percent: '',
        reward_tokens: '',
        reward_CRYG_percent: '',
        can_drop_with_tokens: '',
    });

    const [errors, setErrors] = useState({
        prizesTypes: false,
        chance_drop_percent: false,
        reward_tokens: false,
        reward_CRYG_percent: false,
        can_drop_with_tokens: false,
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };


    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });
        }, 3000);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};


        if (values.can_drop_with_tokens === '') {
            validComplete = false;
            formErrors.can_drop_with_tokens = true;
        }
        if (values.chance_drop_percent === '') {
            validComplete = false;
            formErrors.chance_drop_percent = true;
        }
        if (values.prizesTypes === '') {
            validComplete = false;
            formErrors.prizesTypes = true;
        }
        if (values.reward_CRYG_percent === '') {
            validComplete = false;
            formErrors.reward_CRYG_percent = true;
        }
        if (values.reward_tokens === '') {
            validComplete = false;
            formErrors.reward_tokens = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const clearForm = () => {
        let vals = {...values};

        for (let key in vals) {
            vals[key] = '';
        }

        setValues(vals);
    };

    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            const data = {
                dataAdd: {
                    chance_drop_percent: values.chance_drop_percent,
                    reward_tokens: values.reward_tokens,
                    reward_CRYG_percent: values.reward_CRYG_percent,
                    can_drop_with_tokens: values.can_drop_with_tokens,
                },
                prizeTypeId: values.prizesTypes,
                boosterPackId: boosterPackId
            }

            postU(`admin/boosterPack/boosterPackPrize`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно добавили приз');
                        clearForm();
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', err?.response?.data?.message);
                })
                .finally(() =>{
                    setSubmitDisabled(false);
                });
        }
    };

    useEffect(() =>{
        setIsLoaded(true)
        getU('admin/boosterPack/prizes')
            .then((response) =>{
                if (response.status === 'success'){
                    setPrizesTypes(response.data.positionsPrizes)
                } else{
                    showAlert('error', 'Ошибка сервера');
                }
            })
            .catch((err) =>{
                showAlert('error', err?.response?.data?.message);
            })
            .finally(() =>{
            })
        getU(`boosterPack/getOne/${boosterPackId}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setTitle(resp.data?.boosterPack?.name)
                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при попытке получить бустер пак');
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }, [])

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Add new prize</title>
            </Helmet>
            <Box className="headerWrapper" sx={{pb: 2}}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    {title}
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Бустер паки</li>
                    <li>/</li>
                    <li>Редактирование призов</li>
                    <li>/</li>
                    <li>Добавление нового приза</li>
                </ul>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 2 }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Добавление нового приза бустер паку"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>

                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="prizesTypes">Название</InputLabel>
                                        <Select
                                            labelId="prizesTypes"
                                            name="prizesTypes"
                                            value={values.prizesTypes}
                                            label="Название"
                                            onChange={handleChange}
                                            error={errors.prizesTypes}
                                        >
                                            {
                                                prizesTypes?.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)
                                            }

                                        </Select>
                                    </FormControl>

                                    <TextField
                                        fullWidth
                                        label="Шанс выпада(%)"
                                        margin="normal"
                                        name="chance_drop_percent"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.chance_drop_percent}
                                        variant="outlined"
                                        error={errors.chance_drop_percent}
                                        inputProps={{ maxLength: 12 }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Количество получаемых токенов"
                                        margin="normal"
                                        name="reward_tokens"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.reward_tokens}
                                        variant="outlined"
                                        error={errors.reward_tokens}
                                        inputProps={{ maxLength: 12 }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Награда в кригах(%)"
                                        margin="normal"
                                        name="reward_CRYG_percent"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.reward_CRYG_percent}
                                        variant="outlined"
                                        error={errors.reward_CRYG_percent}
                                        inputProps={{ maxLength: 12 }}
                                    />
                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="can_drop_with_tokens">Может выпасть с токена</InputLabel>
                                        <Select
                                            labelId="can_drop_with_tokens"
                                            name="can_drop_with_tokens"
                                            value={values.can_drop_with_tokens}
                                            label="Может выпасть с токена"
                                            onChange={handleChange}
                                            error={errors.can_drop_with_tokens}
                                        >
                                            <MenuItem value={true}>Да</MenuItem>
                                            <MenuItem value={false}>Нет</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Добавить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default BoosterPackAddPrize;
