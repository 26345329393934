import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider, Alert, CardContent
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/confirm";
import {BallTriangle} from "react-loader-spinner";
import '../../styles/All.css'

const BannersList = () => {

    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();

    const [isLoaded, setIsLoaded] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });
        }, 3000);
    };


    const loadGames = () => {
        setIsLoaded(true)

        let endpoint = `banners?page=${page + 1}&limit=${limit}`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setData(resp.data.banners);
                    setCount(resp?.data?.allCount || 0);
                }
            })
            .catch((err) => {
                showAlert('error', err?.response?.data?.message);
                console.log(err.response)
                setData([]);
                setCount(0);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    const onDelete = (id) => {

        confirm({
            title: 'Удаление',
            content: 'Вы уверены, что хотите удалить баннер?',
            onConfirm: () => {
                deleteU(`banners/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadGames();
                            showAlert('success', 'Вы успешно удалили баннер');
                        }
                    })
                    .catch((e) => {
                        showAlert('error', e?.response?.data?.message);
                        // console.log("opened")
                        // console.log(e.response)
                    });
            }
        });
    };

    useEffect(() => {
        loadGames();
    }, [page, limit]);


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Banners</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Баннеры
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                        {alert.txt}
                    </Alert>
                    <>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Box sx={{marginLeft: 2}}>
                                <Link to="/app/banners/add">
                                    <Button color="primary" variant="contained">
                                        Добавить баннер
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 1000}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{width: 80}}>
                                                        Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Аватар
                                                    </TableCell>
                                                    <TableCell>
                                                        Название
                                                    </TableCell>
                                                    <TableCell>
                                                        Ссылка
                                                    </TableCell>
                                                    <TableCell>

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data?.map((item) => (
                                                    <TableRow hover key={item.id}>
                                                        <TableCell sx={{width: 80}}>
                                                            {item?.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box sx={{alignItems: 'center', display: 'flex'}}>
                                                                <Avatar
                                                                    src={
                                                                        item?.image ?
                                                                            `${item?.image}`
                                                                            :
                                                                            ''
                                                                    }
                                                                />
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.title || '---'}
                                                        </TableCell>

                                                        <TableCell>
                                                            {item?.link || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box sx={{display: 'flex'}}>
                                                                <Box sx={{ml: 2}}>
                                                                    <Link to={`/app/banners/edit/${item?.id}`}>
                                                                        <Button color="primary" variant="contained">
                                                                            Редакт.
                                                                        </Button>
                                                                    </Link>
                                                                </Box>
                                                                <Button
                                                                    sx={{ml: 2}} color="error"
                                                                    variant="contained"
                                                                    onClick={() => onDelete(item?.id)}
                                                                >
                                                                    Удалить
                                                                </Button>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        labelRowsPerPage={<span>Кол-во строк на странице:</span>}
                                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                        colSpan={7}
                                                        count={count}
                                                        rowsPerPage={limit}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeLimit}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>
                </Container>
            </Box>
        </>
    );
};

export default BannersList;
