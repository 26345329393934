import {Navigate} from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account/Account';
import UserInfo from './pages/User/UserInfo';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import UserAdd from './pages/User/UserAdd';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import DeletedUsers from "./pages/User/DeletedUsers";
import EmailSettings from "./pages/Settings/Email/EmailTemplateList";
import EmailTemplateEdit from "./pages/Settings/Email/EmailTemplateEdit";
import EmailTemplateCreate from "./pages/Settings/Email/EmailTemplateCreate";
import Boosters from "./pages/Boosters/Boosters";
import UserUpBalance from "./pages/User/UserUpBalance";
import ReferralLine from "./pages/User/Referrals/ReferralLine";
import ReferralHistory from "./pages/User/Referrals/ReferralHistory";
import UserHistory from "./pages/User/UserHistory";
import Logs from "./pages/Settings/Logs/Logs";
import Config from "./pages/Settings/Configs/Configs";
import ConfigsEdit from "./pages/Settings/Configs/ConfigsEdit";
import PaymentList from "./pages/Payment/PaymentList";
import BoostersInfo from "./pages/User/Bossters/BoostersInfo";
import BoosterAdd from "./pages/User/Bossters/BoosterAdd";
import BoostersStarInfo from "./pages/Boosters/BoostersStarInfo";
import TokensUserAdd from "./pages/User/Tokens/TokensUserAdd";
import BoostersStarEdit from "./pages/Boosters/BoostersStarEdit";
import GarantBonusList from "./pages/GarantBonus/GarantBonusList";
import GarantBonusAdd from "./pages/GarantBonus/GarantBonusAdd";
import GarantBonusEdit from "./pages/GarantBonus/GarantBonusEdit";
import DashboardBoosters from "./pages/Dashboard/DashboardBoosters";
import DashboardTokens from "./pages/Dashboard/DashboardTokens";
import BoosterPackList from "./pages/BoosterPack/BoosterPackList";
import BoosterPackEdit from "./pages/BoosterPack/BoosterPackEdit";
import BoosterPackEditPrizes from "./pages/BoosterPack/BoosterPackEditPrizes";
import BoosterPackAddPrize from "./pages/BoosterPack/BoosterPackAddPrize";
import BoosterPackEditPrize from "./pages/BoosterPack/BoosterPackEditPrize";
import DashboardBoosterPacks from "./pages/Dashboard/DashboardBoosterPacks";
import UserAddReferall from "./pages/User/UserAddReferall";
import GamesList from "./pages/Games/GamesList";
import GamesAdd from "./pages/Games/GamesAdd";
import GamesEdit from "./pages/Games/GamesEdit";
import FreezingList from "./pages/Freezing/FreezingList";
import UserFreezing from "./pages/User/UserFreezing";
import BannersList from "./pages/Banners/BannersList";
import BannersAdd from "./pages/Banners/BannersAdd";
import BannersEdit from "./pages/Banners/BannersEdit";
import PartnerAPI from "./pages/Settings/PartnerAPI/PartnerAPI";
import PartnerAPIEdit from "./pages/Settings/PartnerAPI/PartnerAPIEdit";


const routes = [
    {
        path: 'app',
        element: <DashboardLayout/>,
        children: [
            {path: 'account', element: <Account/>},

            {path: 'dashboard-common', element: <Dashboard/>},
            {path: 'dashboard-tokens', element: <DashboardTokens/>},
            {path: 'dashboard-boosters', element: <DashboardBoosters/>},
            {path: 'dashboard-booster-packs', element: <DashboardBoosterPacks/>},

            {path: 'user/:id', element: <UserInfo/>},
            {path: 'user/:id/freezing', element: <UserFreezing/>},
            {path: 'users/add', element: <UserAdd/>},
            {path: 'user/edit/:id', element: <UserEdit/>},
            {path: 'user/edit/:id/add/referal', element: <UserAddReferall/>},
            {path: 'users/deleted', element: <DeletedUsers/>},
            {path: 'user/up-balance/:id', element: <UserUpBalance/>},
            {path: 'user/:id/history', element: <UserHistory/>},
            {path: 'users', element: <UserList/>},
            {path: 'users/:id/boosters', element: <BoostersInfo/>},
            {path: 'users/:id/boosters/add', element: <BoosterAdd/>},
            {path: 'users/:id/tokens/add', element: <TokensUserAdd/>},

            {path: 'games', element: <GamesList/>},
            {path: 'games/add', element: <GamesAdd/>},
            {path: 'games/edit/:id', element: <GamesEdit/>},

            {path: 'banners', element: <BannersList/>},
            {path: 'banners/add', element: <BannersAdd/>},
            {path: 'banners/edit/:id', element: <BannersEdit/>},

            {path: 'freezing', element: <FreezingList/>},

            {path: 'user/edit/:id/referral-line/:refId', element: <ReferralLine/>},
            {path: 'user/edit/:id/referral-history', element: <ReferralHistory/>},

            {path: 'payment', element: <PaymentList/>},

            {path: 'settings/email', element: <EmailSettings/>},
            {path: 'settings/email/edit/:id', element: <EmailTemplateEdit/>},
            {path: 'settings/email/add', element: <EmailTemplateCreate/>},
            {path: 'logs', element: <Logs/>},
            {path: 'configs', element: <Config/>},
            {path: 'configs/edit/:name', element: <ConfigsEdit/>},
            {path: 'partnerAPI', element: <PartnerAPI/>},
            {path: 'partnerAPI/edit', element: <PartnerAPIEdit/>},

            {path: 'boosters', element: <Boosters/>},
            {path: 'boosters/info/:boosterId', element: <BoostersStarInfo/>},
            {path: 'boosters/info/:boosterId/edit/:boosterStarId', element: <BoostersStarEdit/>},

            {path: 'booster-packs', element: <BoosterPackList/>},
            {path: 'booster-packs/edit/:id', element: <BoosterPackEdit/>},
            {path: 'booster-packs/edit-prizes/:id', element: <BoosterPackEditPrizes/>},
            {path: 'booster-packs/edit-prizes/:boosterPackId/add-prize', element: <BoosterPackAddPrize/>},
            {path: 'booster-packs/edit-prizes/edit/:boosterPackPrizeId', element: <BoosterPackEditPrize/>},

            {path: 'garant-bonus', element: <GarantBonusList/>},
            {path: 'garant-bonus/add', element: <GarantBonusAdd/>},
            {path: 'garant-bonus/edit/:id', element: <GarantBonusEdit/>},

            {path: '*', element: <Navigate to="/404"/>}
        ]
    },
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            {path: 'login', element: <Login/>},
            {path: '404', element: <NotFound/>},
            {path: '/', element: <Navigate to="/app/dashboard-common"/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    }
];

export default routes;
