import {Helmet} from 'react-helmet';
import {
    Box,
    Button, Card,
    Container, Divider,
    Grid, Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow, TextField
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useGet} from '../../API/request';
import {BallTriangle} from 'react-loader-spinner';
import StatisticsItem from "../../components/dashboard/StatisticsItem";
import PerfectScrollbar from "react-perfect-scrollbar";
import Typography from "@material-ui/core/Typography";
import {
    FileText,
} from 'react-feather';

const DashboardBoosterPacks = () => {

    const [loaded, setLoaded] = useState(true);

    const getU = useGet();

    const [boosterPack, setBoosterPack] = useState({});

    useEffect(() => {
        getU('admin/statistic/boosters')
            .then((response) => {
                if (response.status === 'success') {
                    setBoosterPack(response.data.boosterPack)
                }
            })
            .catch(() => {

            })
            .finally(() =>{
                setLoaded(false);
            })
        ;
    }, []);

    if (loaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Бустер пак статистика
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItem
                                title="Количество открытых бустерпаков в общем"
                                data={boosterPack?.sumOpenBoosterPack}
                                unit="boosters"
                                icon={<FileText/>}
                                bckColor={'rgba(104, 93, 216, 0.2)'}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItem
                                title="Количество открытых бустерпаков за сутки"
                                data={boosterPack?.sumOpenBoosterPackLastDay}
                                unit="boosters"
                                icon={<FileText/>}
                                bckColor={'rgba(40, 199, 111, 0.2)'}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};


export default DashboardBoosterPacks;
