import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Typography,
    Input,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useNavigate, useParams} from 'react-router-dom';
import {useGet, usePost} from "../../../API/request";
import React, {useEffect, useState} from "react";
import {BallTriangle} from "react-loader-spinner";

const BoosterAdd = () => {

    const navigate = useNavigate();
    const {id} = useParams();
    const postU = usePost();
    const getU = useGet();

    const [values, setValues] = useState({
        boosterId: '',
        boosterStarId: '',
    });
    const [errors, setErrors] = useState({
        boosterId: false,
        boosterStarId: false,
    });
    const [boosters, setBoosters] = useState([]);
    const [boostersStart, setBoostersStart] = useState([]);

    const [isLoaded, setIsLoaded] = useState(true);
    const [boosterDisabled, setBoosterDisabled] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange =  (event) => {
         setValues({
            ...values,
            [event.target.name]: event.target.value
        });
         setErrors({
            ...errors,
            [event.target.name]: false
        });
    };


    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 3000);
    };

    console.log(values)
    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.boosterId === '') {
            validComplete = false;
            formErrors.boosterId = true;
        }

        if (values.boosterStarId === '') {
            validComplete = false;
            formErrors.boosterStarId = true;
        }


        setErrors(formErrors);
        return validComplete;
    };

    const clearForm = () => {
        let vals = {...values};

        for (let key in vals) {
            vals[key] = '';
        }

        setValues(vals);
    };

    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            postU(`admin/boosters/${values.boosterStarId}/setBoosterUser/${id}`)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно добавили пользователя');
                        clearForm();
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    console.log(err.response)
                    showAlert('error', err.response.data.message);
                })
                .finally(() => {
                    setSubmitDisabled(true);
                });
        }
    };

    useEffect(() => {
        getU(`boosters/`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setBoosters(resp.data.boosters)
                } else {
                    showAlert('error', 'Произошла ошибка при попытке загрузить данные');
                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при попытке загрузить данные');
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }, [])


    const getBoosterStart = () => {
        setBoosterDisabled(true)
        setValues({
            ...values,
            boosterStarId: ''
        })
        getU(`boosters/boosterStar/boosterId/${values.boosterId}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setBoostersStart(resp.data.boostersStars)
                } else {

                }
            })
            .catch(() => {

            })
            .finally(() => {
                setBoosterDisabled(false)
            });
    }

    useEffect(() => {
        getBoosterStart()
    },[values.boosterId])

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Create new booster</title>
            </Helmet>
            <Box className="headerWrapper" sx={{pb: 2}}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Пользователи
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Пользователи</li>
                    <li>/</li>
                    <li onClick={() => navigate("/app/users")}>Информация</li>
                    <li>/</li>
                    <li onClick={() => navigate(-1)}>Бустеры пользователя</li>
                    <li>/</li>
                    <li>Добавление бустера</li>
                </ul>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%'}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 2}}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Создание нового бустера"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="boosterId">Бустер</InputLabel>
                                        <Select
                                            labelId="boosterId"
                                            name="boosterId"
                                            value={values.boosterId}
                                            error={errors.boosterId}
                                            label="Бустер"
                                            onChange={handleChange}
                                        >
                                            {
                                                boosters.map(item =>
                                                    <MenuItem value={item.id}>{item.rang}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        fullWidth sx={{mt: 2, mb: 1}}
                                        disabled={!values.boosterId || boosterDisabled}
                                    >
                                        <InputLabel id="boosterStarId">Бустер стар этап</InputLabel>
                                        <Select
                                            labelId="boosterStarId"
                                            name="boosterStarId"
                                            value={values.boosterStarId}
                                            label="Бустер стар этап"
                                            error={errors.boosterStarId}
                                            onChange={handleChange}
                                        >
                                            {
                                                boostersStart.map(item =>
                                                    <MenuItem value={item.id}>{item.star}</MenuItem>
                                                )
                                            }

                                        </Select>
                                    </FormControl>
                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Создать
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default BoosterAdd;
