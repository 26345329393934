import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Typography,
    Input,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Alert, TableCell
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useNavigate, useParams} from 'react-router-dom';
import {useGet, usePost, usePut} from "../../API/request";
import React, {useEffect, useState} from "react";
import {BallTriangle} from "react-loader-spinner";

const GarantBonusEdit = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const putU = usePut();
    const getU = useGet();

    const [values, setValues] = useState({
        trade_turnover: '',
        amount_bonus: '',
    });
    const [errors, setErrors] = useState({
        trade_turnover: false,
        amount_bonus: false,
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };


    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 3000);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.amount_bonus === '') {
            validComplete = false;
            formErrors.amount_bonus = true;
        }

        if (values.trade_turnover === '') {
            validComplete = false;
            formErrors.trade_turnover = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const clearForm = () => {
        let vals = {...values};

        for (let key in vals) {
            vals[key] = '';
        }

        setValues(vals);
    };

    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            putU(`admin/cumulativeBonus/${id}`, {
                trade_turnover: values.trade_turnover,
                amount_bonus: values.amount_bonus,
            })
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно добавили новый гарант бонус');
                        clearForm();
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    console.log(err.response)
                    showAlert('error', err.response.data.message);
                })
                .finally(() =>{
                    setSubmitDisabled(false)
                })
            ;
        }
    };

    useEffect(() => {
        getU(`admin/cumulativeBonus/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setValues({
                        trade_turnover: resp.data.bonus.trade_turnover,
                        amount_bonus: resp.data.bonus.amount_bonus
                    })
                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при попытке загрузить гарант бонус');
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Edit new garant bonus</title>
            </Helmet>
            <Box className="headerWrapper" sx={{pb: 2}}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Гарант бонусы
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Гарант бонусы</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%'}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 2}}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Редактирование гарант бонуса"
                                />
                                <CardContent sx={{position: 'relative'}}>

                                    <TextField
                                        fullWidth
                                        label="Торговый оборот"
                                        margin="normal"
                                        name="trade_turnover"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.trade_turnover}
                                        variant="outlined"
                                        error={errors.trade_turnover}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Сумма бонуса"
                                        margin="normal"
                                        name="amount_bonus"
                                        onChange={handleChange}
                                        type="number"
                                        value={values.amount_bonus}
                                        variant="outlined"
                                        error={errors.amount_bonus}
                                    />

                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default GarantBonusEdit;
