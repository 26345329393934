import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Typography,
    Input,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useNavigate, useParams} from 'react-router-dom';
import {put, useGet, usePost, usePut} from "../../API/request";
import React, {useEffect, useState} from "react";
import {BallTriangle} from "react-loader-spinner";

const BoosterPackEditPrize = () => {

    const {boosterPackPrizeId} = useParams();

    const navigate = useNavigate();

    const postU = usePost();
    const getU = useGet();
    const putU = usePut();

    const [prizesTypes, setPrizesTypes] = useState([])

    const [title, setTitle] = useState('')
    const [titlePrize, setTitlePrize] = useState('')

    const [values, setValues] = useState({
        prizesTypes: '',
        chance_drop_percent: '',
        reward_tokens: '',
        reward_CRYG_percent: '',
        can_drop_with_tokens: '',
    });

    const [errors, setErrors] = useState({
        prizesTypes: false,
        chance_drop_percent: false,
        reward_tokens: false,
        reward_CRYG_percent: false,
        can_drop_with_tokens: false,
    });

    const [isEdited, setIsEdited] = useState({
        prizesTypes: false,
        chance_drop_percent: false,
        reward_tokens: false,
        reward_CRYG_percent: false,
        can_drop_with_tokens: false,
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
        setIsEdited({
            ...isEdited,
            [event.target.name]: true
        });
    };


    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });
        }, 3000);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};


        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (nothingChanged) {
            showAlert('error', 'Нет изменений');
            return;
        }

        if (validate()) {
            setSubmitDisabled(true);

            const data = {}

            if (isEdited.prizesTypes){
                data.prizesTypes = values.prizesTypes
            }
            if (isEdited.chance_drop_percent){
                data.chance_drop_percent = values.chance_drop_percent
            }
            if (isEdited.reward_tokens){
                data.reward_tokens = values.reward_tokens
            }
            if (isEdited.reward_CRYG_percent){
                data.reward_CRYG_percent = values.reward_CRYG_percent
            }
            if (isEdited.can_drop_with_tokens){
                data.can_drop_with_tokens = values.can_drop_with_tokens
            }

            putU(`admin/boosterPack/prizeBoosterPack/${boosterPackPrizeId}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно обновили приз');
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', err?.response?.data?.message);
                })
                .finally(() =>{
                    setSubmitDisabled(false);
                });
        }
    };

    const getData = async () =>{
        await setIsLoaded(true)
        await getU('admin/boosterPack/prizes')
            .then((response) =>{
                if (response.status === 'success'){
                    setPrizesTypes(response.data.positionsPrizes)
                } else{
                    showAlert('error', 'Ошибка сервера');
                }
            })
            .catch((err) =>{
                showAlert('error', err?.response?.data?.message);
            })
            .finally(() =>{

            })

        await getU(`admin/boosterPack/prizeBoosterPack/${boosterPackPrizeId}`)
            .then((response) =>{
                if (response.status === 'success'){
                    setValues({
                        prizesTypes: response.data.prizeBoosterPack.type_prize_id,
                        chance_drop_percent: response.data.prizeBoosterPack.chance_drop_percent,
                        reward_tokens: response.data.prizeBoosterPack.reward_tokens,
                        reward_CRYG_percent: response.data.prizeBoosterPack.reward_CRYG_percent,
                        can_drop_with_tokens: response.data.prizeBoosterPack.can_drop_with_tokens,
                    })
                    setTitle(response.data?.boosterPack?.name)
                    setTitlePrize(response.data?.prize?.name)

                } else{
                    showAlert('error', 'Ошибка сервера');
                }
            })
            .catch((err) =>{
                showAlert('error', err?.response?.data?.message);
            })
            .finally(() =>{
                setIsLoaded(false)
            })
    }

    useEffect(() =>{
        getData()
    }, [])

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Edit prize</title>
            </Helmet>
            <Box className="headerWrapper" sx={{pb: 2}}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    {titlePrize}
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Бустер паки</li>
                    <li>/</li>
                    <li>Редактирование призов {title}</li>
                    <li>/</li>
                    <li>Редактирование приза</li>
                </ul>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 2 }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Редактирование приза бустер пака"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>

                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="prizesTypes">Название</InputLabel>
                                        <Select
                                            labelId="prizesTypes"
                                            name="prizesTypes"
                                            value={values.prizesTypes}
                                            label="Название"
                                            onChange={handleChange}
                                        >
                                            {
                                                prizesTypes?.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)
                                            }

                                        </Select>
                                    </FormControl>

                                    <TextField
                                        fullWidth
                                        label="Шанс выпада(%)"
                                        margin="normal"
                                        name="chance_drop_percent"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.chance_drop_percent}
                                        variant="outlined"
                                        error={errors.chance_drop_percent}
                                        inputProps={{ maxLength: 12 }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Количество получаемых токенов"
                                        margin="normal"
                                        name="reward_tokens"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.reward_tokens}
                                        variant="outlined"
                                        error={errors.reward_tokens}
                                        inputProps={{ maxLength: 12 }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Награда в кригах(%)"
                                        margin="normal"
                                        name="reward_CRYG_percent"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.reward_CRYG_percent}
                                        variant="outlined"
                                        error={errors.reward_CRYG_percent}
                                        inputProps={{ maxLength: 12 }}
                                    />
                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="can_drop_with_tokens">Может выпасть с токена</InputLabel>
                                        <Select
                                            labelId="can_drop_with_tokens"
                                            name="can_drop_with_tokens"
                                            value={values.can_drop_with_tokens}
                                            label="Может выпасть с токена"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={1}>Да</MenuItem>
                                            <MenuItem value={0}>Нет</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default BoosterPackEditPrize;
