import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Typography,
    Input,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import {usePost} from "../../API/request";
import {useState} from "react";

const BannersAdd = () => {

    const navigate = useNavigate();
    const postU = usePost();

    const [uploadedImg, setUploadedImg] = useState('/static/images/defaultImage.jpg');

    const [values, setValues] = useState({
        title: '',
        image: '',
        link: '',
    });
    const [errors, setErrors] = useState({
        title: false,
        image: false,
        link: false,
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };


    const avaUploaded = (event) => {
        setUploadedImg(URL.createObjectURL(event.target.files[0]));
        setValues({
            ...values,
            image: event.target.files[0]
        });
    };


    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 3000);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.title.trim() === '') {
            validComplete = false;
            formErrors.title = true;
        }

        if (values.link.trim() === '') {
            validComplete = false;
            formErrors.link = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const clearForm = () => {
        let vals = {...values};

        for (let key in vals) {
            vals[key] = '';
        }

        setValues(vals);
    };

    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            let data = new FormData();
            data.append("title", values.title);
            data.append("file", values.image);
            data.append("link", values.link);

            postU('banners', data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно добавили новый баннер');
                        clearForm();
                        setUploadedImg('/static/images/defaultImage.jpg');
                    } else {
                        showAlert('error', 'Ошибка');
                    }

                })
                .catch((err) => {
                    console.log(err.response)
                    showAlert('error', err?.response?.data?.message);
                })
                .finally(() =>{
                    setSubmitDisabled(false);
                })
        }
    };

    return (
        <>
            <Helmet>
                <title>Create new banner</title>
            </Helmet>
            <Box className="headerWrapper" sx={{ pb: 2 }}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Баннеры
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Баннеры</li>
                    <li>/</li>
                    <li>Добавление</li>
                </ul>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 2 }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Создание новой баннера"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>

                                    <div className="itemWrapper">
                                        <div className="container">
                                            <input accept="xlsx/*" type="file" style={{display: 'none'}}
                                                   id={1}
                                                   onChange={(event) => avaUploaded(event, 1)}/>
                                            <label htmlFor={1}>
                                                <img src={uploadedImg} className="itemImg"/>
                                                <div className="middle"/>
                                            </label>
                                        </div>
                                        <div className="help-text">
                                            Доступны следующие расширения: .png .jpg .svg .bmp .tga .webp
                                        </div>
                                    </div>

                                    <TextField
                                        fullWidth
                                        label="Название"
                                        margin="normal"
                                        name="title"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.title}
                                        variant="outlined"
                                        error={errors.title}
                                    />

                                    <TextField
                                        fullWidth
                                        label="Ссылка"
                                        margin="normal"
                                        name="link"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.link}
                                        variant="outlined"
                                        error={errors.link}
                                    />
                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Создать
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default BannersAdd;
