import {Helmet} from 'react-helmet';
import {
    Box,
    Button, Card,
    Container, Divider,
    Grid, Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow, TextField
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useGet} from '../../API/request';
import {BallTriangle} from 'react-loader-spinner';
import StatisticsItem from "../../components/dashboard/StatisticsItem";
import PerfectScrollbar from "react-perfect-scrollbar";
import Typography from "@material-ui/core/Typography";
import {
    FileText,
} from 'react-feather';

const DashboardBoosters = () => {

    const [loaded, setLoaded] = useState(true);

    const getU = useGet();

    const [sumAllBooster, setSumAllBooster] = useState({});

    useEffect(() => {
        getU('admin/statistic/boosters')
            .then((response) => {
                if (response.status === 'success') {
                    setSumAllBooster(response.data.booster.sumAllBooster)
                }
            })
            .catch(() => {

            })
            .finally(() =>{
                setLoaded(false);
            })
        ;
    }, []);

    if (loaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Бустер статистика
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
                <Container maxWidth={false}>
                    <Container
                        style={{
                            marginTop: "20px",
                            padding: 0,
                        }}
                        maxWidth={true}>
                        <Card>
                            <PerfectScrollbar>
                                <Box >
                                    <Typography
                                        variant="h4"
                                        component="h4"
                                        style={{
                                            padding: 15,
                                        }}
                                    >
                                        Общее количество бустеров по рангам и уровням прокачки у всех пользователей
                                    </Typography>
                                    <Divider/>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{width: 80}}>
                                                    Имя бустера
                                                </TableCell>
                                                <TableCell>
                                                    Звезды
                                                </TableCell>
                                                <TableCell>
                                                    Мощность
                                                </TableCell>
                                                <TableCell>
                                                    Цена улучшения
                                                </TableCell>
                                                <TableCell>
                                                    Баланс количества
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sumAllBooster?.map((item) => (
                                                <TableRow hover >
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.booster_name}
                                                    </TableCell>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.star}
                                                    </TableCell>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.power}
                                                    </TableCell>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.price_pumping}
                                                    </TableCell>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.quantityBalance}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Container>
                </Container>
            </Box>
        </>
    );
};


export default DashboardBoosters;
