import { configureStore } from '@reduxjs/toolkit'
import auth from "./slices/auth";
import dashboard from "./slices/dashboard";

export const store = configureStore({
    reducer: {
        auth,
        dashboard
    },
})

