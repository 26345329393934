import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useDelete, useGet, usePut} from '../../../API/request';
import {BallTriangle} from "react-loader-spinner";
import Typography from "@material-ui/core/Typography";

const PartnerAPI = () => {

    const getU = useGet();
    const putU = usePut();

    const [isLoaded, setIsLoaded] = useState(true);

    const [secretKey, setSecretKey] = useState();

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

        }, 3000);
    };


    const loadData = () => {
        setIsLoaded(true)

        getU('users/balances/secret')
            .then((resp) => {
                console.log(resp)
                if (resp.status === 'success') {
                    setSecretKey(resp.data.key);
                }
            })
            .catch((err) => {
                showAlert('error', err?.response?.data?.message);
                setSecretKey('');
            })
            .finally(() => {
                setIsLoaded(false)
            });

    };


    useEffect(() => {
        loadData();
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Партнерское API</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Партнерское API
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '90%', py: 3}}>

                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none', marginBottom: 20}}>
                        {alert.txt}
                    </Alert>
                    <>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 600}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Ключ

                                                    </TableCell>

                                                    <TableCell>

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow hover>
                                                    <TableCell>
                                                        {secretKey || "---"}
                                                    </TableCell>
                                                    <TableCell sx={{width: 100}}>
                                                        <Link to={`/app/partnerAPI/edit`}>
                                                            <Button color="primary" variant="contained">
                                                                Редактирование
                                                            </Button>
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                        <Card style={{
                            marginTop: 20
                        }}>
                            <Box style={{
                                padding: 20,
                            }}>
                                <Typography style={{
                                    color: '#b1b1b1'
                                }}>
                                    Данный запрос предназначен для получения всех пользователей со списком их почт и
                                    балансов.
                                    Чтобы выполнить запрос, необходимо выполнить POST-запрос по пути <span style={{fontWeight: 700}}>{`{domain}`}/api/users/balances/export </span>
                                    В теле запроса, нужно передать параметр, который представлен выше:
                                </Typography>
                                <Typography style={{
                                    color: '#b1b1b1'
                                }}>
                                    <span style={{fontWeight: 700}}>{`{domain}`}
                                    "secretKey";{`{apiKey}`}
                                    </span>
                                </Typography>
                            </Box>
                        </Card>
                    </>
                </Container>
            </Box>
        </>
    );
};

export default PartnerAPI;
