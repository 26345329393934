import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Divider,
    TextField
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {useGet, usePost, usePut} from "../../../API/request";
import {BallTriangle} from "react-loader-spinner";
import "../../../styles/Configs/style.css"

const PartnerAPIEdit = () => {

    const {name} = useParams();
    const navigate = useNavigate();

    const postU = usePost();
    const getU = useGet();
    const putU = usePut();

    const [values, setValues] = useState({
        key: '',
    });
    const [errors, setErrors] = useState({
        key: false,
    });

    const [isLoaded, setIsLoaded] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);
    const [dataBeforeChange, setDataBeforeChange] = useState({
        key: ''
    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setNothingChanged(false);
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 3000);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.key === '') {
            validComplete = false;
            formErrors.key = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (nothingChanged) {
            showAlert('error', 'Ничего не изменилось');
            return;
        }
        if (validate()) {
            setSubmitDisabled(true);

            if (dataBeforeChange.key === values.key){
                return showAlert('error', 'Вы пытаетесь изменить на такое же значение');
            }

            putU(`users/balances/secret`, {key: values.key})
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно обновили партнерское api');
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', err?.response?.data?.message);
                })
                .finally(() => {

                });
        }
    };


    useEffect(() => {
        setIsLoaded(true)

        getU(`users/balances/secret`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setValues({
                        key: resp.data.key
                    });
                    setDataBeforeChange({
                        key: resp.data.key
                    })
                }
            })
            .catch((err) => {
                showAlert('error', err?.response?.data?.message);
            })
            .finally(() => {
                setIsLoaded(false)
            });

    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Партнерское API Edit</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Партнерское API
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Партнерское API</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default', minHeight: '85%', py: 3}}>
                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Обновление секретного ключа для получения балансов пользователя"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        label="Ключ"
                                        margin="normal"
                                        name="key"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.key}
                                        variant="outlined"
                                        error={errors.key}
                                    />
                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default PartnerAPIEdit;
