import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Avatar,
    Container,
    Button,
    Card,
    CardContent,
    Divider,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Typography, Alert, TextField
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {makeStyles} from '@material-ui/styles';
import {Link as RouterLink, useParams, useNavigate, Link} from 'react-router-dom';
import {useGet, usePut} from '../../API/request';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../../styles/Users/Users.css'
import {BallTriangle} from "react-loader-spinner";
import moment from "moment";

const BoostersStarInfo = () => {

    const navigate = useNavigate();

    const getU = useGet();
    const putU = usePut();
    const {boosterId} = useParams();

    const [data, setData] = useState({});
    const [info, setInfo] = useState({});

    const [isLoaded, setIsLoaded] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });
        }, 3000);
    };

    useEffect(() => {
        setIsLoaded(true)

        getU(`boosters/boosterStar/boosterId/${boosterId}`)
            .then((resp) => {
                console.log(resp.data)
                if (resp.status === 'success') {
                    setData(resp.data.boostersStars)
                    setInfo(resp.data.booster)
                } else {
                    showAlert('error', 'Ошибка при получение данных о бустере');
                }
            })
            .catch(() => {
                showAlert('error', 'Систная ошибка, повторите позже');
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }, []);

    const onSave = (id) =>{
        let find = data.find(item => item.id === id);

        putU(`admin/boosters/star/${id}`, {
            token_pumping_id: find.token_pumping_id,
            price_pumping: find.price_pumping,
            power: find.power
        })
            .then((resp) => {
                if (resp.status === 'success') {
                    showAlert('success', 'Данные успешно обновленны');
                } else {
                    showAlert('error', 'Ошибка');
                }
            })
            .catch((err) => {
                console.log(err.response)
                showAlert('error', err?.response?.data?.message);
            })
            .finally(() => {

            })
    }

    const onChangeValue = ({id, event}) =>{
        setData(data.map(item => (
            item.id === id
                ? {...item, [event.target.name]: event.target.value}
                : item
        )));
    }

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Бустеры</title>
            </Helmet>
            <Box className="headerWrapper" sx={{pb: 2}}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    {info?.rang}
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Бустеры</li>
                    <li>/</li>
                    <li>Информация</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default', py: 1}}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{pt: 3}}>
                        <Card>
                            <Typography style={{margin: '20px'}}>
                                Стоимость покупки бустера: {info.price_token}
                            </Typography>
                            <PerfectScrollbar>
                                <Box sx={{minWidth: 300}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Id
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Количество звезд
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Цена улучшения бустера
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Мощность
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Дата создания
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                data?.map((item) => {
                                                    return (
                                                        <TableRow style={{textAlign: 'center',}}>
                                                            <TableCell style={{textAlign: 'center',}} sx={{width: 80}}>
                                                                {item?.id}
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {item?.star}
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Цена улучшения бустера"
                                                                    margin="normal"
                                                                    name="price_pumping"
                                                                    onChange={(e) => onChangeValue({
                                                                        id: item.id,
                                                                        event: e
                                                                    })}
                                                                    type="text"
                                                                    value={item?.price_pumping}
                                                                    variant="outlined"
                                                                />
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Мощность"
                                                                    margin="normal"
                                                                    name="power"
                                                                    onChange={(e) => onChangeValue({
                                                                        id: item.id,
                                                                        event: e
                                                                    })}
                                                                    type="text"
                                                                    value={item.power}
                                                                    variant="outlined"
                                                                />
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {moment(item.date_create).format('DD/MM/YYYY')}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    onClick={() => onSave(item.id)}
                                                                    color="primary"
                                                                    variant="contained"
                                                                >
                                                                    Сохранить
                                                                </Button>
                                                                {/*<Link to={`/app/boosters/info/${boosterId}/edit/${item.id}`}>*/}
                                                                {/*    <Button color="primary" variant="contained">*/}
                                                                {/*        Ред.*/}
                                                                {/*    </Button>*/}
                                                                {/*</Link>*/}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>

        </>
    );
};

export default BoostersStarInfo;
