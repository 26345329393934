import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/confirm";
import {BallTriangle} from "react-loader-spinner";
import '../../styles/All.css'

const GarantBonusList = () => {

    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();

    const [isLoaded, setIsLoaded] = useState(true);
    const [data, setData] = useState([]);

    const loadUsers = () => {
        setIsLoaded(true)

        let endpoint = `admin/cumulativeBonus`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setData(resp.data.bonuses);
                }
            })
            .catch((err) => {
                console.log(err.response)
                setData([]);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };

    const onDelete = (id) => {
        confirm({
            title: 'Удаление',
            content: 'Вы уверены, что хотите удалить данный гарант бонус?',
            onConfirm: () => {
                deleteU(`admin/cumulativeBonus/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadUsers();
                        }
                    })
                    .catch((e) => {
                        // console.log("opened")
                        // console.log(e.response)
                    });
            }
        });
    };

    useEffect(() =>{
        loadUsers();
    },[])

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Garant Bonus</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Гарант-бонусы
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Box sx={{marginLeft: 2}}>
                                <Link to="/app/garant-bonus/add">
                                    <Button color="primary" variant="contained">
                                        Добавить гарант бонус
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 700}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{width: 80}}>
                                                        Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Торговый оборот
                                                    </TableCell>
                                                    <TableCell>
                                                        Сумма бонуса
                                                    </TableCell>
                                                    <TableCell>

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.map((item) => (
                                                    <TableRow hover key={item.id}>
                                                        <TableCell sx={{width: 80}}>
                                                            {item.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.trade_turnover || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.amount_bonus || '---'}
                                                        </TableCell>
                                                        <TableCell style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                            <Box sx={{display: 'flex'}}>
                                                                <Box sx={{mr: 2}}>
                                                                    <Link
                                                                        to={`/app/garant-bonus/edit/${item.id}`}>
                                                                        <Button color="primary" variant="contained">
                                                                            Редактировать
                                                                        </Button>
                                                                    </Link>
                                                                </Box>
                                                                <Button
                                                                    onClick={() => onDelete(item.id)}
                                                                    color="error"
                                                                    variant="contained"
                                                                >
                                                                    Удалить
                                                                </Button>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>
                </Container>
            </Box>
        </>
    );
};

export default GarantBonusList;
