import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider, Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/confirm";
import {BallTriangle} from "react-loader-spinner";
import moment from "moment";

const Boosters = () => {

    const [isLoaded, setIsLoaded] = useState(false);

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const confirm = useConfirm();
    const getU = useGet();

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });
        }, 3000);
    };


    const loadUsers = () => {
        setIsLoaded(true)

        let endpoint = `boosters?page=${page + 1}&limit=${limit}`;

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setData(resp.data.boosters);
                } else {
                    showAlert('error', 'Ошибка при получение данных о пользователе');
                }
            })
            .catch((err) => {
                console.log(err.response)
                setData([]);
                showAlert('error', 'Систная ошибка, повторите позже');
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };

    useEffect(() =>{
        loadUsers()
    }, [])

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Бустеры</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Бустеры
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                        {alert.txt}
                    </Alert>
                    <>
                        <Box sx={{pt: 3}}>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 1050}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Выбор токена
                                                    </TableCell>
                                                    <TableCell>
                                                        Название
                                                    </TableCell>
                                                    <TableCell>
                                                        Стоимость покупки бустера
                                                    </TableCell>
                                                    <TableCell>
                                                        Дата создания
                                                    </TableCell>
                                                    <TableCell>

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.map((item) => (
                                                    <TableRow hover key={item.id}>
                                                        <TableCell sx={{width: 80}}>
                                                            {item.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.token_id || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.rang || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.price_token || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {moment(item.date_create).format('DD/MM/YYYY')}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Link to={`/app/boosters/info/${item.id}`}>
                                                                <Button color="primary" variant="contained">
                                                                    Инфо.
                                                                </Button>
                                                            </Link>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                        </Box>
                    </>
                </Container>
            </Box>
        </>
    );
};

export default Boosters;
