import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Avatar,
    Container,
    Button,
    Card,
    CardContent,
    Divider,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {makeStyles} from '@material-ui/styles';
import {Link as RouterLink, useParams, useNavigate, Link} from 'react-router-dom';
import {useGet} from '../../../API/request';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../../../styles/Users/Users.css'
import {BallTriangle} from "react-loader-spinner";

const BoostersInfo = () => {

    const getU = useGet();
    const {id} = useParams();
    const [user, setUser] = useState({});
    const [boosters, setBoosters] = useState([]);

    const [isLoaded, setIsLoaded] = useState(true);

    const navigate = useNavigate();


    const getData = () =>{
        setIsLoaded(true)

        getU(`admin/user/${id}`)
            .then((resp) => {
                console.log(resp.data)
                if (resp.status === 'success') {
                    setUser(resp.data.user)
                }
            })
            .catch(() => {

            })
            .finally(() => {

            });

        getU(`admin/boosters/user/${id}`)
            .then((resp) => {
                console.log(resp.data)
                if (resp.status === 'success') {
                    setBoosters(resp.data.boosters)
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setIsLoaded(false)
            });
    }

    useEffect(() => {
        getData()
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>User</title>
            </Helmet>
            <Box className="headerWrapper" sx={{pb: 2}}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Бустеры пользователя
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Пользователи</li>
                    <li>/</li>
                    <li onClick={() => navigate("/app/users")}>Информация</li>
                    <li>/</li>
                    <li>Бустеры пользователя</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default'}}>

                <Container maxWidth={false} >
                    <Card>
                        <CardContent sx={{p: 3}}>
                            <div className="wrapAvatar">
                                <Avatar src={user.avatar} className="avatar"/>
                                <div>
                                    <div className="wrap">
                                        <div className="label">
                                            ID:
                                        </div>
                                        <div className="text">
                                            {user?.id || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Роль:
                                        </div>
                                        <div className="text">
                                            {user?.role?.user_role_russia || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Login:
                                        </div>
                                        <div className="text">
                                            {user?.login || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Имя:
                                        </div>
                                        <div className="text">
                                            {user?.name || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Фамилия:
                                        </div>
                                        <div className="text">
                                            {user?.surname || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Email:
                                        </div>
                                        <div className="text">
                                            {user?.email || '---'}
                                        </div>
                                    </div>
                                </div>
                                <div className="blanceWrappe">
                                    <div className="balanceBlock">
                                        <div className="balanceTextFirst">
                                            {user?.wallet?.balance?.amount} CRYG
                                        </div>
                                        <div className="balanceTextSecond">
                                            {user?.wallet?.balance?.amount * Number(user?.wallet?.info?.value)} RUB
                                        </div>
                                        <div className="balanceTextThird">
                                            {user?.wallet?.info?.value} RUB за 1 CRYG
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Container>
            </Box>

            <Box sx={{backgroundColor: 'background.default', py: 1}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 3}}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{minWidth: 300}}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography
                                            sx={{p: 2}}
                                            variant="h6"
                                            id="tableTitle"
                                            component="div"
                                        >
                                            Бустеры
                                        </Typography>
                                        <Link to={`/app/users/${id}/boosters/add`}>
                                            <Button color="primary"
                                                    variant="contained"
                                                    sx={{
                                                        m: 2,
                                                        minWidth: 120
                                                    }}
                                            >
                                                Добавить бустер
                                            </Button>
                                        </Link>
                                    </Box>

                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    ID
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Название
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Кол-во звезд
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Главный/не главный
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Цена улучшения бустера
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Можность бустера
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                boosters.map((item) => {
                                                    return (
                                                        <TableRow style={{textAlign: 'center',}} sx={{width: 80}}>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {item.id}
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {item.rang}
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {item.star}
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {item?.is_main === 1 ? 'Да' : 'Нет' }
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {item?.price_pumping}
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {item.power}
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default BoostersInfo;
