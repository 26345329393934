import {Helmet} from 'react-helmet';
import {
    Box,
    Button, Card,
    Container, Divider,
    Grid, Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow, TextField
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useGet} from '../../API/request';
import {BallTriangle} from 'react-loader-spinner';
import StatisticsItem from "../../components/dashboard/StatisticsItem";
import PerfectScrollbar from "react-perfect-scrollbar";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {FileText} from "react-feather";

const DashboardTokens = () => {

    const [loaded, setLoaded] = useState(true);

    const getU = useGet();

    const [tokenCryg, setTokenCryg] = useState({});
    const [tokenRang, setTokenRang] = useState({});

    useEffect(() => {
        getU('admin/statistic/coins')
            .then((response) => {
                if (response.status === 'success') {
                    setTokenCryg(response.data.tokenCryg)
                    setTokenRang(response.data.tokenRang)
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setLoaded(false);
            });
    }, []);

    if (loaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Токен статистика
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItem
                                title="Общий % стейкинга у всех участников."
                                data={tokenCryg?.totalPercentStaking || 0}
                                unit="%"
                                icon={<FileText/>}
                                bckColor={'rgba(104, 93, 216, 0.2)'}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItem
                                title="Количество монет на стейкинге"
                                data={tokenCryg?.amountCrygOnStaking}
                                unit="coins"
                                icon={<FileText/>}
                                bckColor={'rgba(40, 199, 111, 0.2)'}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItem
                                title="Количество монет не на стейкинге, а на свободном балансе"
                                data={tokenCryg?.amountCrygInBalanceUsers}
                                unit="coins"
                                icon={<FileText/>}
                                bckColor={'rgba(255, 159, 67, 0.2)'}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItem
                                title="Количество монет заработанных по стейкингу за сутки"
                                data={tokenCryg?.quantityEarnedBitchesOnStakingInDay}
                                unit="coins"
                                icon={<FileText/>}
                                bckColor={'rgba(0, 207, 232, 0.2)'}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItem
                                title="Количество сожженных монет при открытии бустерпаков"
                                data={tokenCryg?.quantityCoinsBurnedBoosterPacksBuy}
                                unit="coins"
                                icon={<FileText/>}
                                bckColor={'rgba(227, 227, 227, 0.2)'}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <StatisticsItem
                                title="Количество сожженных монет при покупке бустерпаков"
                                data={tokenCryg?.quantityEarnedBitchesOnStakingInDay}
                                unit="coins"
                                icon={<FileText/>}
                                bckColor={'rgba(1, 91, 248, 0.2)'}
                            />
                        </Grid>
                    </Grid>

                    <Container style={{marginTop: "20px", padding: 0,}} maxWidth={true}>
                        <Card>
                            <PerfectScrollbar>
                                <Box>
                                    <Typography
                                        variant="h4"
                                        component="h4"
                                        style={{
                                            padding: 15,
                                        }}
                                    >
                                        Общее количество токенов потраченных на прокачку бустера
                                    </Typography>
                                    <Divider/>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{width: 80}}>
                                                    Имя
                                                </TableCell>
                                                <TableCell>
                                                    Сумма
                                                </TableCell>
                                                <TableCell>
                                                    Бустре стары
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tokenRang?.quantitySpentImprovementBooster?.map((item) => (
                                                <TableRow hover>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.name}
                                                    </TableCell>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.sumAll}
                                                    </TableCell>
                                                    <TableCell sx={{width: 80}}>
                                                        {
                                                            item?.boostersStars?.join(', ') || '---'
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Container>

                    <Container style={{marginTop: "20px", padding: 0,}} maxWidth={true}>
                        <Card>
                            <PerfectScrollbar>
                                <Box>
                                    <Typography
                                        variant="h4"
                                        component="h4"
                                        style={{
                                            padding: 15,
                                        }}
                                    >
                                        Количество токенов потраченных на прокачку бустера за сутки
                                    </Typography>
                                    <Divider/>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{width: 80}}>
                                                    Имя
                                                </TableCell>
                                                <TableCell>
                                                    Сумма
                                                </TableCell>
                                                <TableCell>
                                                    Бустре стары
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tokenRang?.quantitySpentImprovementBoosterLastDay?.map((item) => (
                                                <TableRow hover>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.name}
                                                    </TableCell>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.sumAll}
                                                    </TableCell>
                                                    <TableCell sx={{width: 80}}>
                                                        {
                                                            item?.boostersStars?.join(', ') || '---'
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Container>

                    <Container style={{marginTop: "20px", padding: 0,}} maxWidth={true}>
                        <Card>
                            <PerfectScrollbar>
                                <Box>
                                    <Typography
                                        variant="h4"
                                        component="h4"
                                        style={{
                                            padding: 15,
                                        }}
                                    >
                                        Общее количество токенов выбитых из паков по рангам
                                    </Typography>
                                    <Divider/>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{width: 80}}>
                                                    Имя
                                                </TableCell>
                                                <TableCell>
                                                    Сумма
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tokenRang?.quantityManyTokens?.map((item) => (
                                                <TableRow hover>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.name}
                                                    </TableCell>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.sum}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Container>

                    <Container style={{marginTop: "20px", padding: 0,}} maxWidth={true}>
                        <Card>
                            <PerfectScrollbar>
                                <Box>
                                    <Typography
                                        variant="h4"
                                        component="h4"
                                        style={{
                                            padding: 15,
                                        }}
                                    >
                                        Количество токенов выбитых из паков по рангам за сутки
                                    </Typography>
                                    <Divider/>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{width: 80}}>
                                                    Имя
                                                </TableCell>
                                                <TableCell>
                                                    Сумма
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tokenRang?.quantityManyTokensLastDay?.map((item) => (
                                                <TableRow hover>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.name || 0}
                                                    </TableCell>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.sum || 0}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Container>

                    <Container style={{marginTop: "20px", padding: 0,}} maxWidth={true}>
                        <Card>
                            <PerfectScrollbar>
                                <Box>
                                    <Typography
                                        variant="h4"
                                        component="h4"
                                        style={{
                                            padding: 15,
                                        }}
                                    >
                                        Количество токенов на балансах пользователей
                                    </Typography>
                                    <Divider/>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{width: 80}}>
                                                    Имя
                                                </TableCell>
                                                <TableCell>
                                                    Баланс пользователей
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tokenRang?.quantityTokensUsersBalances?.map((item) => (
                                                <TableRow hover>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.name || 0}
                                                    </TableCell>
                                                    <TableCell sx={{width: 80}}>
                                                        {item?.quantityBalanceUsers || 0}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Container>
                </Container>
            </Box>
        </>
    );
};


export default DashboardTokens;
