import {Helmet} from 'react-helmet';
import {Box, Button, Container, Grid} from '@material-ui/core';
import CustomersStatistics from '../../components/dashboard/CustomersStatistics';
import LatestOrders from '../../components/dashboard/LatestOrders';
import LatestProducts from '../../components/dashboard/LatestProducts';
import Sales from '../../components/dashboard/Sales';
import ProfitPerMonth from '../../components/dashboard/ProfitPerMonth';
import DelStatistics from '../../components/dashboard/DelStatistics';
import TotalProfit from '../../components/dashboard/TotalProfit';
import TrafficByDevice from '../../components/dashboard/TrafficByDevice';
import React, {useEffect, useState} from 'react';
import {useGet} from '../../API/request';
import {BallTriangle} from 'react-loader-spinner';
import CrygStatistics from "../../components/dashboard/CrygStatistics";
import StatisticsItems from "../../components/dashboard/StatisticsItems";
import StatisticsItem from "../../components/dashboard/StatisticsItem";
import {apiUrl} from "../../API/environment";

const Dashboard = () => {

        const [loaded, setLoaded] = useState(true);

        const getU = useGet();

        const [data, setData] = useState({
            quantityCRYG: 0,
            quantityDEL: 0,
            quantityUsers: 0,
            quantityReferralRegistration: 0,
            quantityReferralBonusDeposit: 0
        });

        const [fileToLoad, setFileToLoad] = useState();

        useEffect(() => {
                getU('admin/statistics')
                    .then((response) => {
                        if (response.status === 'success') {
                            setData({
                                quantityCRYG: response.data.quantityCRYG || 0,
                                quantityDEL: response.data.quantityDEL || 0,
                                quantityUsers: response.data.quantityUsers,
                                quantityReferralRegistration: response.data.quantityReferralRegistration || 0,
                                quantityReferralBonusDeposit: response.data.quantityReferralBonusDeposit || 0
                            });
                        }
                    })
                    .catch(() => {

                    })
                    .finally(() => {

                    });

                getU('admin/users/balances/xlsx')
                    .then((response) => {
                        if (response.status === 'success') {
                            if (response?.path?.length > '1') {
                                setFileToLoad(`${apiUrl}/uploads/${response?.path}`)
                            } else {
                                setFileToLoad(``)
                            }
                        }
                    })
                    .catch(() => {
                        setFileToLoad(``)
                    })
                    .finally(() => {
                        setLoaded(false);
                    });
            }, []
        );

        const onDownload = () => {
            const fileUrl = fileToLoad;
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        };

        console.log(fileToLoad)

        if (loaded) {
            return (
                <div className="loader">
                    <BallTriangle
                        height="100"
                        width="100"
                        color='grey'
                        ariaLabel='loading'
                    />
                </div>
            )
        }

        return (
            <>
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>
                <Box className="headerWrapper">
                    <Box className="headerTitle">
                        Общая статистика
                    </Box>
                </Box>

                <Box
                    sx={{
                        backgroundColor: 'background.default',
                        minHeight: '100%',
                        py: 3
                    }}
                >

                    <Container maxWidth={false}>
                        <Box
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                marginBottom: '20px'
                            }}
                        >
                            <Button
                                sx={{ml: 2}}
                                color="primary"
                                variant="contained"
                                disabled={fileToLoad?.length < 1}
                                onClick={onDownload}
                            >
                                Выгрузить балансы пользователей в файл
                            </Button>
                        </Box>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                lg={3}
                                sm={6}
                                xl={4}
                                xs={12}
                            >
                                <StatisticsItems
                                    count={data?.quantityUsers}
                                    bcgColor={"rgba(104, 93, 216, 0.2)"}
                                    name={"Subscribers"}
                                    desc={"Общее количество пользователей"}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                sm={6}
                                xl={4}
                                xs={12}
                            >
                                <StatisticsItems
                                    count={data?.quantityDEL}
                                    bcgColor={"rgba(40, 199, 111, 0.2)"}
                                    name={"DEL"}
                                    desc={"Общее количество DEL"}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                sm={6}
                                xl={4}
                                xs={12}
                            >
                                <StatisticsItems
                                    count={data?.quantityCRYG}
                                    bcgColor={"rgba(255, 159, 67, 0.2)"}
                                    name={"CRYG"}
                                    desc={"Количество купленных CRYG"}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                sm={6}
                                xl={4}
                                xs={12}
                            >
                                <StatisticsItems
                                    count={data?.quantityReferralRegistration}
                                    bcgColor={"rgba(0, 207, 232, 0.2)"}
                                    name={"CRYG"}
                                    desc={"Количество полученных CRYG пользователями за приглашенного реферрала"}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                sm={6}
                                xl={4}
                                xs={12}
                            >
                                <StatisticsItems
                                    count={data?.quantityReferralBonusDeposit}
                                    bcgColor={"rgba(227, 227, 227, 0.2)"}
                                    name={"CRYG"}
                                    desc={"Количество полученных CRYG пользователями за % который их рефераллы ввели как депозит в приложение"}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </>
        );
    }
;


export default Dashboard;
