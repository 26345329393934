import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider, Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../../skeletons/UserListSkelet';
import {useDelete, useGet, usePut} from '../../../API/request';
import {useConfirm} from "../../../components/confirm";
import {BallTriangle} from "react-loader-spinner";

const Config = () => {

    const getU = useGet();
    const putU = usePut();
    const confirm = useConfirm();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const [configs, setConfigs] = useState([]);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

        }, 3000);
    };



    const loadUsers = () => {
        setIsDataLoading(true);
        setIsLoaded(true)

        getU('admin/setting')
            .then((resp) => {
                console.log(resp)
                if (resp.status === 'success') {
                    setConfigs(resp.data.settings);
                }
            })
            .catch((err) => {
                console.log(err.response)
                setConfigs([]);
            })
            .finally(() => {
                setIsLoaded(false)
                setIsDataLoading(false);
            });

    };


    useEffect(() => {
        loadUsers();
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Users</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Конфиги
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '90%', py: 3}}>

                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none', marginBottom: 20}}>
                        {alert.txt}
                    </Alert>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 600}}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Наименование
                                                            </TableCell>
                                                            <TableCell>
                                                                Значение
                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {configs?.map((item) => (
                                                            <TableRow hover key={item.id}>
                                                                <TableCell>
                                                                    {item.name_setting || "---"}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item?.is_image
                                                                        ? <Box sx={{alignItems: 'center', display: 'flex'}}>
                                                                            <Box
                                                                                component="img"
                                                                                sx={{
                                                                                    height: 50,
                                                                                    width: 50,
                                                                                    maxHeight: { xs: 233, md: 167 },
                                                                                    maxWidth: { xs: 350, md: 250 },
                                                                                }}
                                                                                alt="img"
                                                                                src={item.value}
                                                                            />
                                                                        </Box>
                                                                        : item.value || "---"
                                                                    }
                                                                </TableCell>
                                                                <TableCell sx={{width: 100}}>
                                                                    <Link to={`/app/configs/edit/${item.name_setting}`}>
                                                                        <Button color="primary" variant="contained">
                                                                            Редактирование
                                                                        </Button>
                                                                    </Link>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default Config;
