import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Typography,
    Input,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useParams, useNavigate} from 'react-router-dom';
import {useGet, usePut} from "../../API/request";
import {BallTriangle} from "react-loader-spinner";

const BoostersStarEdit = () => {

    const {boosterStarId} = useParams();
    const navigate = useNavigate();

    const getU = useGet();
    const putU = usePut();

    const [values, setValues] = useState({
        token_pumping_id: '',
        power: '',
        price_pumping: '',

    });
    const [errors, setErrors] = useState({
        token_pumping_id: false,
        power: false,
        price_pumping: false,
    });

    const [tokens, setTokens] = useState([]);

    const [isLoaded, setIsLoaded] = useState(true);

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const [dataBeforeChange, setDataBeforeChange] = useState({
        price_pumping: '',
        power: '',
        token_pumping_id: ''
    });

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const resetPass = () => {
        setValues({
            ...values,
            password: '',
            confirm: ''
        })
    }

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.token_pumping_id === '') {
            validComplete = false;
            formErrors.token_pumping_id = true;
        }

        if (values.power === '') {
            validComplete = false;
            formErrors.power = true;
        }

        if (values.price_pumping === '') {
            validComplete = false;
            formErrors.price_pumping = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (nothingChanged) {
            showAlert('error', 'Нет изменений');
            return;
        }

        if (validate()) {
            setSubmitDisabled(true);

            const payload = {};

            if (values.power !== dataBeforeChange.power) {
                payload.power = values.power;
            }

            if (values.token_pumping_id !== dataBeforeChange.token_pumping_id) {
                payload.token_pumping_id = values.token_pumping_id;
            }

            if (values.price_pumping !== dataBeforeChange.price_pumping) {
                payload.price_pumping = values.price_pumping;
            }

            putU(`admin/boosters/star/${boosterStarId}`, payload)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Данные успешно обновленны');
                        resetPass();
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    console.log(err.response)
                    showAlert('error', err?.response?.data?.message);
                })
                .finally(() => {
                    setSubmitDisabled(false);
                })
            ;
        }
    };

    useEffect(() => {
        getU(`boosters/boosterStar/${boosterStarId}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    console.log(resp)
                    let data = resp.data.boosterStar;
                    let vals = {
                        price_pumping: data.price_pumping,
                        power: data.power,
                        token_pumping_id: data.token_pumping_id
                    };

                    let beforeChange = {
                        price_pumping: data.price_pumping,
                        power: data.power,
                        token_pumping_id: data.token_pumping_id
                    };

                    setDataBeforeChange(beforeChange);
                    setValues(vals)
                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при попытке загрузить данные');
            })
            .finally(() => {
                setIsLoaded(false)
            });
        getU(`tokensBooster`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setTokens(resp.data.tokens)
                } else {
                    showAlert('error', 'Произошла ошибка при попытке загрузить данные');
                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при попытке загрузить данные');
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Редактирование бустер стара</title>
            </Helmet>

            <Box className="headerWrapper" sx={{pb: 2}}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Бустеры
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Бустеры</li>
                    <li>/</li>
                    <li onClick={() => navigate(-1)}>Информация</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>
            </Box>


            <Box sx={{backgroundColor: 'background.default', minHeight: '100%'}}>
                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Редактирование бустер стара"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                        <InputLabel id="token_pumping_id">Токен, за который производится улучшение
                                            бустера</InputLabel>
                                        <Select
                                            labelId="token_pumping_id"
                                            name="token_pumping_id"
                                            value={values.token_pumping_id}
                                            label="токен, за который производится улучшение бустера"
                                            onChange={handleChange}
                                        >
                                            {
                                                tokens.map(item =>
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )
                                            }

                                        </Select>
                                    </FormControl>
                                    <TextField
                                        fullWidth
                                        label="Стоимость улучшения бустера в токенах"
                                        margin="normal"
                                        name="price_pumping"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.price_pumping}
                                        variant="outlined"
                                        error={errors.price_pumping}
                                    />
                                    <TextField
                                        fullWidth
                                        label="power"
                                        margin="normal"
                                        name="power"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.power}
                                        variant="outlined"
                                        error={errors.power}
                                    />

                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>


                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default BoostersStarEdit;
