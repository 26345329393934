import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    TextField,
    Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useParams, useNavigate} from 'react-router-dom';
import {useGet, usePost, usePut} from "../../API/request";
import {BallTriangle} from "react-loader-spinner";
import ModalGetUser from "../../components/ModalGetUser/ModalGetUser";

const UserAddReferall = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const getU = useGet();
    const postU = usePost();

    const [isLoaded, setIsLoaded] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [user, setUser] = useState({});

    const [modalStatus, setModalStatus] = useState({
        isOpen: false,
        field: null,
    });

    const [values, setValues] = useState({
        userRefId: '',
        userRefEmail: ''
    });
    const [errors, setErrors] = useState({
        userRefId: false,
        userRefEmail: false
    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChangeUserRef = ({email, id}) => {
        if (email){
            setValues({
                userRefId: id,
                userRefEmail: email,
            })
        }
    }

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validateInfo = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (!values.userRefEmail) {
            validComplete = false;
            formErrors.userRefEmail = false;
            showAlert('error', "Выберите пользователя")
        }

        setErrors(formErrors);
        return validComplete;
    };


    const submitInfo = async () => {
        if (validateInfo()) {
            setSubmitDisabled(true);

            const data = {
                referralAcceptUserId: id,
                toInviteUserId: values.userRefId
            }

            postU(`admin/user/addReferral`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно добавили реферального пользователя');
                        setTimeout(() => {
                            getUserData()
                        }, 2500)
                    }
                })
                .catch((err) => {
                    showAlert('error', err.response.data.message);
                })
                .finally(() => {
                    setSubmitDisabled(false);
                })
            ;
        }
    };

    const getUserData = () => {
        setIsLoaded(true)
        getU(`admin/user/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    if (resp.data?.user?.applainer){
                        setValues({
                            userRefId: id,
                            userRefEmail: resp.data?.user?.applainer
                        })
                    }
                    setUser(resp.data?.user)
                }
            })
            .catch((err) => {
                showAlert('error', err.response.data.message);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }

    useEffect(() => {
        getUserData()
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Add ref user</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Добавление реферала
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-2)}>Пользователи</li>
                    <li>/</li>
                    <li onClick={() => navigate(-1)}>Редактирование</li>
                    <li>/</li>
                    <li>Добавление реферала</li>
                </ul>

            </Box>

            <Box sx={{backgroundColor: 'background.default', pt: 3}}>
                <Container maxWidth={false}>

                    {user?.applainer
                        ?
                        <Alert severity={'error'} style={{display: 'flex', marginBottom: 20}}>
                            Вы уже добавили реферального пользователя
                        </Alert>
                        :
                        <Alert severity={'warning'} style={{display: 'flex', marginBottom: 20}}>
                            Внимание! при побавлении реферального пользователя вы больше не сможете его изменить
                        </Alert>

                    }


                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none', marginBottom: 20}}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{
                        paddingBottom: 1
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Добавление реферального пользователя и его ветку другому пользователя"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        label="Реферальный пользователь"
                                        margin="normal"
                                        name="userRefEmail"
                                        type="text"
                                        value={values.userRefEmail}
                                        variant="outlined"
                                        error={errors.userRefEmail}
                                    />
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Button
                                            onClick={() => setModalStatus({
                                                isOpen: true,
                                                field: 'coOwner'
                                            })}
                                            color="primary"
                                            variant="contained"
                                            disabled={user?.applainer}
                                        >
                                            Выберите пользователя
                                        </Button>
                                    </Box>

                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submitInfo}
                                        disabled={submitDisabled || user?.applainer}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
                <ModalGetUser
                    ownerId={user?.id}
                    isOpenModal={modalStatus}
                    setIsOpenModal={setModalStatus}
                    handleChangeUserRef={handleChangeUserRef}
                />
            </Box>
        </>
    );
};

export default UserAddReferall;
