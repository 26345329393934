import {createSlice} from '@reduxjs/toolkit';
import {configureTransport} from "../../API/transport";
import {post} from "../../API/request";

const initialState = {
    accessToken: '',
    refreshToken: '',
    tokenExpire: '',
    user: {},
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setTokens: (state, action) => {
            configureTransport(action.payload.accessToken);
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            state.tokenExpire = action.payload.tokenExpire;
            state.user = action.payload.user
        },
        setUser: (state, action) => {
            state.user = action.payload || {};
        },
    },
});

export const fetchLogin = (dispatch, {
    data, onSuccess = () => {}, onFailed = () => {},
}) => {
    post('auth/login', data).then((response) => {
        if (response.status === 'success') {

            if (response.data.user.role.user_role !== "User") {
                dispatch(setTokens({
                    accessToken: response.data.user.access_token,
                    refreshToken: response.data.refresh_token,
                    user: response.data.user,
                }));
                onSuccess(response.data);
            } else {
                onFailed(response)
            }
        } else {
            onFailed(response)
        }
    }).catch((error) => {
        onFailed(error);
    });
};

export const logout = (dispatch) => {
    dispatch(setTokens({
        accessToken: '',
        refreshToken: '',
        user: {},
        isAuth: false
    }));
};

export const {setTokens, setRememberMe, setLoginData, setUser} = authSlice.actions;

export default authSlice.reducer;
