import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Avatar,
    Container,
    Button,
    Card,
    CardContent,
    Divider,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Typography, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {makeStyles} from '@material-ui/styles';
import {Link as RouterLink, useParams, useNavigate, Link} from 'react-router-dom';
import {useGet} from '../../API/request';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../../styles/Users/Users.css'
import {BallTriangle} from "react-loader-spinner";
import moment from "moment";

const UserInfo = () => {

    const getU = useGet();
    const {id} = useParams();
    const [user, setUser] = useState({});
    const [tokens, setTokens] = useState({});

    const [isLoaded, setIsLoaded] = useState(true);

    const navigate = useNavigate();

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });
        }, 3000);
    };

    const getData = async () => {
        setIsLoaded(true)

        await getU(`admin/user/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setUser(resp.data.user)
                } else {
                    showAlert('error', 'Ошибка при получение данных о пользователе');
                }
            })
            .catch(() => {
                showAlert('error', 'Ошибка при получение данных о пользователе');
            })
            .finally(() => {

            });

        await getU(`tokensBooster/user/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setTokens(resp.data.tokens)
                }
            })
            .catch(() => {
                showAlert('error', 'Ошибка при получение токенов пользователя');
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }

    useEffect(() => {
        getData()
    }, []);

    const testData = [
        {
            id: 1,
            line: 1,
        },
        {
            id: 2,
            line: 2,
        },
        {
            id: 3,
            line: 3,
        },
        {
            id: 4,
            line: 4,
        },
        {
            id: 5,
            line: 5,
        },
        {
            id: 6,
            line: 6,
        },

    ]

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>User</title>
            </Helmet>
            <Box className="headerWrapper" sx={{pb: 2}}>
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Пользователи
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate("/app/users")}>Пользователи</li>
                    <li>/</li>
                    <li>Информация</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default'}}>

                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}}>
                        <Box sx={{marginLeft: 2}}>
                            <Link to={`/app/users/${id}/boosters`}>
                                <Button color="warning" variant="contained">
                                    Бустеры
                                </Button>
                            </Link>
                        </Box>
                        <Box sx={{marginLeft: 2}}>
                            <Link to={`/app/user/${id}/history`}>
                                <Button color="warning" variant="contained">
                                    История транзакций
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                    <Card>
                        <CardContent sx={{p: 3}}>
                            <div className="wrapAvatar">
                                <Avatar src={user?.avatar} className="avatar"/>
                                <div>
                                    <div className="wrap">
                                        <div className="label">
                                            ID:
                                        </div>
                                        <div className="text">
                                            {user?.id || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Роль:
                                        </div>
                                        <div className="text">
                                            {user?.role?.user_role_russia || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Login:
                                        </div>
                                        <div className="text">
                                            {user?.login || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Имя:
                                        </div>
                                        <div className="text">
                                            {user?.name || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Фамилия:
                                        </div>
                                        <div className="text">
                                            {user?.surname || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Email:
                                        </div>
                                        <div className="text">
                                            {user?.email || '---'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Количество открытых бустер паков:
                                        </div>
                                        <div className="text">
                                            {user?.countOpensBoosterPack || '0'}
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="label">
                                            Подтвердил почту:
                                        </div>
                                        <div className="text">
                                            {user?.is_verification ? "Да" : "Нет" || '---'}
                                        </div>
                                    </div>
                                    {user?.verificationLink &&
                                    <div className="wrap">
                                        <div className="label">
                                            Ссылка подтверждения почты:
                                        </div>
                                        <div className="text">
                                            {user?.verificationLink || '---'}
                                        </div>
                                    </div>
                                    }
                                    <div className="wrap">
                                        <div className="label">
                                            Десятичный ключ:
                                        </div>
                                        <div className="text">
                                            {user?.decimal_wallet || '---'}
                                        </div>
                                    </div>
                                </div>
                                <div className="blanceWrappe">
                                    <div className="balanceBlock">
                                        <div className="balanceTextFirst">
                                            {user?.wallet?.balance?.amount} CRYG
                                        </div>
                                        <div className="balanceTextSecond">
                                            {user?.wallet?.balance?.amount * Number(user?.wallet?.info?.value)} RUB
                                        </div>
                                        <div className="balanceTextThird">
                                            {user?.wallet?.info?.value} RUB за 1 CRYG
                                        </div>
                                    </div>
                                    {/*<div className="statingWrap">*/}
                                    {/*    {*/}
                                    {/*        stateItems.map((item) => (*/}
                                    {/*            <div className="stateItem" key={item.id}>*/}
                                    {/*                <img*/}
                                    {/*                    src={item.img}*/}
                                    {/*                    alt=""*/}
                                    {/*                    className="stateItemAva"*/}
                                    {/*                />*/}
                                    {/*                <div className="stateItemName">*/}
                                    {/*                    {*/}
                                    {/*                        item.name*/}
                                    {/*                    }*/}
                                    {/*                </div>*/}
                                    {/*                <div className="stateItemCount">*/}
                                    {/*                    {*/}
                                    {/*                        item.count*/}
                                    {/*                    }*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        ))*/}
                                    {/*    }*/}


                                    {/*</div>*/}
                                </div>

                            </div>
                        </CardContent>
                    </Card>
                </Container>
            </Box>

            <Box sx={{backgroundColor: 'background.default', py: 1}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 3}}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{minWidth: 300}}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography
                                            sx={{p: 2}}
                                            variant="h6"
                                            id="tableTitle"
                                            component="div"
                                        >
                                            Информация о фарминге
                                        </Typography>
                                    </Box>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Заработано за все время
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Активна ли
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Процент фарминга в день
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Мощность
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Баланс на фарминге
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Замороженные средства этого пользователя
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                user?.farming &&
                                                <TableRow style={{textAlign: 'center',}} sx={{width: 80}}>
                                                    <TableCell style={{textAlign: 'center',}}>
                                                        {user?.farming?.extracted || '---'}
                                                    </TableCell>
                                                    <TableCell style={{textAlign: 'center',}}>
                                                        {user?.farming?.isActive ? "Да" : "Нет" || '---'}
                                                    </TableCell>
                                                    <TableCell style={{textAlign: 'center',}}>
                                                        {user?.farming?.percentDay || '---'}
                                                    </TableCell>
                                                    <TableCell style={{textAlign: 'center',}}>
                                                        {user?.farming?.power || '---'}
                                                    </TableCell>
                                                    <TableCell style={{textAlign: 'center',}}>
                                                        {user?.farming?.stakingBalance || '---'}
                                                    </TableCell>
                                                    <TableCell style={{textAlign: 'center',}}>
                                                        <Link
                                                            to={`/app/user/${user.id}/freezing`}
                                                            style={user?.farming?.arrayFreeze?.length === 0 ? {pointerEvents: 'none'} : {}}
                                                        >
                                                            <Button color="primary" variant="contained"
                                                                    disabled={user?.farming?.arrayFreeze?.length === 0}
                                                            >
                                                                Посмотреть
                                                            </Button>
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>

            <Box sx={{backgroundColor: 'background.default', py: 1}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 3}}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{minWidth: 300}}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography
                                            sx={{p: 2}}
                                            variant="h6"
                                            id="tableTitle"
                                            component="div"
                                        >
                                            Гарант бонусы пользователя
                                        </Typography>

                                    </Box>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Id
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Торговый оборот
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Сумма бонуса
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                user?.bonuses?.map((item) => {
                                                    return (
                                                        <TableRow style={{textAlign: 'center',}} sx={{width: 80}}>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {item.id || '---'}
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {item.trade_turnover || '---'}
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {item.trade_turnover || '---'}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>

            <Box sx={{backgroundColor: 'background.default', py: 1}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 3}}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{minWidth: 300}}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography
                                            sx={{p: 2}}
                                            variant="h6"
                                            id="tableTitle"
                                            component="div"
                                        >
                                            Токены
                                        </Typography>
                                        <Link to={`/app/users/${user?.id}/tokens/add`}>
                                            <Button color="primary"
                                                    variant="contained"
                                                    sx={{
                                                        m: 2,
                                                        minWidth: 120
                                                    }}
                                            >
                                                Добавить токен
                                            </Button>
                                        </Link>
                                    </Box>

                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Токен за который покупается бустер
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Сумма
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Дата создания
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                tokens?.map((item) => {
                                                    return (
                                                        <TableRow style={{textAlign: 'center',}}>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {item.token_name}
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {item.amount}
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                {moment(item.date_create).format('DD/MM/YYYY')}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>

            <Box sx={{backgroundColor: 'background.default', py: 1}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 3}}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{minWidth: 300}}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography
                                            sx={{p: 2}}
                                            variant="h6"
                                            id="tableTitle"
                                            component="div"
                                        >
                                            Рефералы
                                        </Typography>
                                        <Link to={`/app/user/edit/${user?.id}/referral-history`}>
                                            <Button color="primary"
                                                    variant="contained"
                                                    sx={{
                                                        m: 2,
                                                        minWidth: 120
                                                    }}
                                            >
                                                История рефералов
                                            </Button>
                                        </Link>
                                    </Box>

                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Линия
                                                </TableCell>
                                                <TableCell style={{textAlign: 'center',}}>
                                                    Просмотреть
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                testData?.map((item) => {
                                                    return (
                                                        <TableRow style={{textAlign: 'center',}}>
                                                            <TableCell style={{textAlign: 'center',}}>
                                                                Линия {
                                                                item.line
                                                            }
                                                            </TableCell>
                                                            <TableCell style={{textAlign: 'center',}} sx={{ml: 2}}>
                                                                <Link
                                                                    to={`/app/user/edit/${user?.id}/referral-line/${item.line}`}>
                                                                    <Button color="primary"
                                                                            variant="contained"
                                                                    >
                                                                        Информация
                                                                    </Button>
                                                                </Link>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>

        </>
    );
};

export default UserInfo;
